import * as React from 'react';
import List from '@mui/material/List';
import './request.css';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import ImageIcon from '@mui/icons-material/Image';
import Grid from "@mui/material/Unstable_Grid2";
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Nav from "./../nav/nav";
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import ButtonGroup from '@mui/material/ButtonGroup';
import FormHelperText from '@mui/material/FormHelperText';
import ListSubheader from '@mui/material/ListSubheader';
//import FavoriteBorder from '@mui/icons-material/SwapVertBorder';
import Favorite from '@mui/icons-material/SwapVert';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import {  blueGrey } from '@mui/material/colors';
import Button from "@mui/material/Button";
import Divider from '@mui/material/Divider';
import { json } from 'react-router-dom';



export default function FolderList() {
  const color = blueGrey[50];
  const [side, setSide] = React.useState(0);
  const [open, setOpen] = React.useState(false);

  const [next, setNext] = React.useState("1");
  const [scroll, setScroll] = React.useState("");
  const [checked, setChecked] = React.useState(true);
 


  const array=[
    {
      type: 'Left',
      basic_model: '',
      armrest: '',
      jonstick_otto: '',
      jonstick_htl_q: '',
      jonstick_htl_tnpe: '',
      button_1_to_9_q: false,
      led1: '',
      led3: '',
      led4: '',
      led6: '',
      led7: '',
      led9: '',
      button_10_to_12_q: false,
      led10: '',
      led11: '',
      led12: '',
      button_13_to_15_q: false,
      led13: '',
      led14: '',
      led15: '',
      button_16_to_18_position: '',
      led16: '',
      led17: '',
      led18: '',
      button_19_to_21_q: false,
      led19: '',
      led20: '',
      led21: '',
      roller_htw: '',
      led_indicator_option: 'no',
      extra_switch_op_q: '',
      extra_switch_op_selected: '',
      push_button_switch:"no",
      customer_logo:"false",
      t: ''
    },{
      type: 'Right',
      basic_model: '',
      armrest: '',
      jonstick_otto: '',
      jonstick_htl_q: '',
      jonstick_htl_tnpe: '',
      button_1_to_9_q: false,
      led1: '',
      led3: '',
      led4: '',
      led6: '',
      led7: '',
      led9: '',
      button_10_to_12_q: false,
      led10: '',
      led11: '',
      led12: '',
      button_13_to_15_q: false,
      led13: '',
      led14: '',
      led15: '',
      button_16_to_18_position: '',
      led16: '',
      led17: '',
      led18: '',
      button_19_to_21_q: false,
      led19: '',
      led20: '',
      led21: '',
      roller_htw: '',
      led_indicator_option: 'no',
      extra_switch_op_q: '',
      extra_switch_op_selected: '',
      push_button_switch:"no",
      customer_logo:"false",
      t: ''
    }
    
  ];

              

  const add = async () => {
    localStorage.setItem("array",JSON.stringify(data))
    const em=  localStorage.getItem("email")
    const com=  localStorage.getItem("com")
    const name=  localStorage.getItem("name")
    const mach=  localStorage.getItem("mach")
    const model=  localStorage.getItem("model")
    const phone=  localStorage.getItem("phone")
    const datum = localStorage.getItem("datum")
    const dd = JSON.stringify(data);

    await fetch(`/api/add.php?email=${em}&com=${com}&name=${name}&mach=${mach}&model=${model}&phone=${phone}&datum=${datum}&array=${dd}`)
      .then((response) => response.json())
      .then((json) => {
       // setAllCom(json);
       if(json[0].msg=="pass")
       {
        console.log(json)
       // window.location.href="/perview?id="+json[0].id;

        window.location = '/perview?id=' + json[0].id;

       }else{

       }

      })
      .catch((err) => {
        console.log(err);
      });
  };


  const [data, setData]   = React.useState(array);

  const changeDataArray = (i, changes) => {


    if(i==3)
    {
      const oldArr = [...data];
      const oldObj_a = oldArr[0];
      const oldObj_b= oldArr[1];

      oldArr[0] = { ...oldObj_a, ...changes };
      oldArr[1] = { ...oldObj_b, ...changes };
  
     
       
      setData(oldArr);



    }else{
      const oldArr = [...data];
      const oldObj = oldArr[i];
      oldArr[i] = { ...oldObj, ...changes };
  
      console.log("abc is here",oldArr[i])
       
      setData(oldArr);

    }
   

  }


  const asLike = (i) => {

    if(i==0)
    {  
      const oldArr = [...data];

      oldArr[1]=oldArr[0];
      oldArr[1].type="Right";
       
      setData(oldArr);

    }else{

      const oldArr = [...data];

      oldArr[0]=oldArr[1];
      oldArr[0].type="Left";
       
      setData(oldArr);


    }
      
    
  }
  

  // sx={{ bgcolor: '#878800' }}
  return (
    <>
    <div >
    
    <Grid style={{marginTop:"0px"}} container spacing={0}>
    <Grid sx={{ bgcolor: '#878800' }}  xs={3}>
   <h4 style={{paddingLeft:'15px'}}> 
   <img src={"/pics/in.png"} />
    </h4>
    </Grid>
    <Grid sx={{ bgcolor: '#878800' }}  xs={3}>
    </Grid>

    <Grid  sx={{ bgcolor: '#878800' }} xs={3}>
    <h4 style={{fontSize:"20px",color:"#fff"}} >Left {side==0?<CheckCircleIcon  sx={{fontSize:"20px",color:"#fff"}} />:<></>} <br/><Button variant="contained" style={{margin:"0px",}} endIcon={<NavigateNextIcon />} onClick={()=>{asLike(1)}} size="small">Replicate like Right</Button>   </h4>
    </Grid>  

    <Grid sx={{ bgcolor: '#878800' }}  xs={3}>
    <h4 style={{fontSize:"20px",color:"#fff"}} >Right {side==1?<CheckCircleIcon  sx={{fontSize:"20px",color:"#fff"}} />:<></>} <br/><Button variant="contained" style={{margin:"0px",}} endIcon={<NavigateNextIcon />} onClick={()=>{asLike(0)}} size="small">Replicate like Left</Button> </h4>
    </Grid>
    </Grid>
    


<Grid container spacing={0}>
  <Grid    xs={3}>

 
   <List sx={{ width: '100%', maxWidth: 360, bgcolor: color,fontSize:"15px"}}>
        <ListItem>
        
<h4><center>Components</center></h4>

        </ListItem>
        
        <ListItem>
       
          <FormControl component="fieldset">
          <FormLabel component="legend">EP2006 basic model incl CAN </FormLabel>
          
          <RadioGroup
           row
              aria-labelledby="demo-error-radios"
              
               value={side==0?"Left":"Right"}
               onChange={(v)=>{ 
                if(v.target.value=="Left"){
                  setSide(0)

                }else if(v.target.value=="Right"){
                  setSide(1)
                }
               }}
            >
              
              <table>
                <tr>
                  <td><FormControlLabel value="Left"  control={<Radio size="small"  />} label="Left" /></td>
                  <td><FormControlLabel value="Right" control={<Radio size="small"   />} label="Right" /></td>
                
                </tr>
              </table>
              
              
            </RadioGroup>

        </FormControl>
          </ListItem>

        {
          next==="1"?<>
          
         
          

    
          <ListItem>
          <FormControl component="fieldset">
          <FormLabel component="legend">EP2006 Armrest bracket Sittab</FormLabel>
          
          <RadioGroup
           row
              aria-labelledby="demo-error-radios"
              
               value={data[side].armrest}
               onChange={(e)=>{changeDataArray(side,{armrest:e.target.value})}}
            >
              
              <table>
                <tr>
                  <td><FormControlLabel value="2LK"  control={<Radio size="small"  />} label="2LK" /></td>
                  <td>  <img src='/pics/side/armrest_bracket/ab1.png' height={"80px"} /></td>
                </tr>
                <tr>
                  <td><FormControlLabel value="2LL/LK" control={<Radio size="small"   />} label="2LL/LK" /></td>
                  <td>  <img src='/pics/side/armrest_bracket/ab2.png' height={"80px"} /></td>
                </tr>
              </table>
              
              
            </RadioGroup>

        </FormControl>
          </ListItem>
    
    
    {/* option 2 */}
    
          <ListItem>
          <FormControl component="fieldset">
          <FormLabel component="legend">EP2006 Joystick Otto JHM *</FormLabel>

          <RadioGroup
           row
              aria-labelledby="demo-error-radios"
              name="demo"
              value={data[side].jonstick_otto}
               onChange={(e)=>{changeDataArray(side,{jonstick_otto:e.target.value})}}
            >
              
             <table>
             <tr>
                <td>
                  <FormControlLabel value="std" height={"30px"} width="30px" control={<Radio  size="small"  />} label="std" />
                  </td><td>
                   <img src='/pics/side/joy_stick_otto/standard_j1.png' height={"80px"} /> </td>
              </tr>
              <tr>
                <td><FormControlLabel value="extended" height={"30px"} width="30px" control={<Radio size="small"  />} label="extended" />
                </td><td>
                  <img src='/pics/side/joy_stick_otto/extended_j2.png' height={"80px"} /> </td>
              </tr>
              <tr>

                <td>  <FormControlLabel value="5-button" height={"30px"} width="30px" control={<Radio size="small"  />} label="5-button" />
                </td><td>
                  <img src='/pics/side/joy_stick_otto/5button_j3.png' height={"80px"} /> </td>
              </tr>
             </table>
              </RadioGroup>





              
    
            
    
        </FormControl>
          </ListItem>
    
           </>:next=="2"?<>
           {/* option 3 */}
          <ListItem>
          {/* <image sx={{ width: 500, height: 450 }} src="/pics/Joystick_HTL/2.png" /> */}
        <FormControl fullWidth sx={{ m: 1, minWidth: 120 }}>
            <InputLabel htmlFor="grouped-select">Extra Joystick optional</InputLabel>
            <Select defaultValue="" 
             value={data[side].jonstick_htl_tnpe}
               onChange={(e)=>{
                var s=e.target.value;
               var d="";
                if(s=="")
                {

                }else{
                  if(s=="A1")
                {
                  d="A"

                }else if(s=="A2")
                {
                  d="A"
                }else if(s=="A3")
                {
                  d="A"

                }else if(s=="A4")
                {
                  d="A"
                }else{
                  d="B"
                }
                }
                changeDataArray(side,{jonstick_htl_tnpe:e.target.value,jonstick_htl_q:d})
                }} id="grouped-select" label="Extra Joystick options">
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <ListSubheader><table> <tr><td>Position A</td></tr>
              <tr>
                <td><img src='/pics/side/joystick_htl/xj1a.png' height={"80px"} /> </td></tr> </table>    </ListSubheader>
              <MenuItem value={"A1"} disabled={data[side].button_16_to_18_position=="b"?true:data[side].button_16_to_18_position=="c"?true:false} ><img src={"/pics/side/joystick_htl/1.png"} style={{height:"80px"}} /></MenuItem>
              <MenuItem value={"A2"} disabled={data[side].button_16_to_18_position=="b"?true:data[side].button_16_to_18_position=="c"?true:false} ><img src={"/pics/side/joystick_htl/2.png"} style={{height:"80px"}} /></MenuItem>
              <MenuItem value={"A3"} disabled={data[side].button_16_to_18_position=="b"?true:data[side].button_16_to_18_position=="c"?true:false}><img src={"/pics/side/joystick_htl/3.png"} style={{height:"80px"}} /></MenuItem>
              <MenuItem value={"A4"} disabled={data[side].button_16_to_18_position=="b"?true:data[side].button_16_to_18_position=="c"?true:false}><img src={"/pics/side/joystick_htl/4.png"} style={{height:"80px"}} /> </MenuItem>
              <ListSubheader><table> <tr><td>Position B</td></tr>
              <tr>
                <td><img src='/pics/side/joystick_htl/xj1b.png' height={"80px"} /> </td></tr> </table>  </ListSubheader>
              <MenuItem  value={"B1"}  disabled={data[side].button_16_to_18_position=="a"?true:false}  ><img src={"/pics/side/joystick_htl/1.png"} style={{height:"80px"}} /></MenuItem>
              <MenuItem value={"B2"} disabled={data[side].button_16_to_18_position=="a"?true:false} ><img src={"/pics/side/joystick_htl/2.png"} style={{height:"80px"}} /></MenuItem>
              <MenuItem value={"B3"} disabled={data[side].button_16_to_18_position=="a"?true:false}><img src={"/pics/side/joystick_htl/3.png"} style={{height:"80px"}} /></MenuItem>
              <MenuItem value={"B4"} disabled={data[side].button_16_to_18_position=="a"?true:false}><img src={"/pics/side/joystick_htl/4.png"} style={{height:"80px"}} /></MenuItem>
            </Select>
          </FormControl>
    
          </ListItem>
                {/* option 4 */}
      <ListItem>
      <FormControl component="fieldset">
      {/* <FormLabel component="legend">keyboard 1-9</FormLabel> */}
      <FormGroup aria-label="position" row>
        
        
        <FormControlLabel
          value="end"
         
          control={<Checkbox 
            checked={data[side].button_1_to_9_q}
            
           
    

               onChange={(e)=>{
                
                if(e.target.checked==true)
                {
                  changeDataArray(side,{button_1_to_9_q:e.target.checked})

                }else{
                  changeDataArray(side,{button_1_to_9_q:e.target.checked,led1:"",led3:"",led4:"",led6:"",led7:"",led9:""})

                }
              }}
                

            /> }
          label="keyboard 1-9"

        
        />
      </FormGroup>
    </FormControl>





      </ListItem>

{
  data[side].button_1_to_9_q?<>
 <ListItem >
  
 </ListItem>

      <ListItem >
      <FormControl component="fieldset">
      <FormLabel component="legend">LED Indicator 1</FormLabel>
      <RadioGroup
       row
          aria-labelledby="demo-error-radios"
          name="quiz"
          value={data[side].led1}
               onChange={(e)=>{changeDataArray(side,{led1:e.target.value})}}
        >
          <FormControlLabel value="r" sx={{backgroundColor:"red"}} control={<Radio  size="small"  ></Radio>} label="R" />
          <FormControlLabel value="y" sx={{backgroundColor:"yellow"}} control={<Radio size="small"  />} label="Y" />
          <FormControlLabel value="b" sx={{backgroundColor:"blue"}} control={<Radio size="small"  />} label="B" />
          <FormControlLabel value="g" sx={{backgroundColor:"green"}} control={<Radio size="small"  />} label="G" />
          <FormControlLabel value=""  control={<Radio size="small"  />} label="Null" />
        </RadioGroup>

        

    </FormControl>
      </ListItem>

      <ListItem>
      <FormControl component="fieldset">
      <FormLabel component="legend">LED Indicator 3</FormLabel>
      <RadioGroup
       row
          aria-labelledby="demo-error-radios"
          name="quiz"
          value={data[side].led3}
          onChange={(e)=>{changeDataArray(side,{led3:e.target.value})}}
        >
         <FormControlLabel value="r" sx={{backgroundColor:"red"}} control={<Radio  size="small"  ></Radio>} label="R" />
          <FormControlLabel value="y" sx={{backgroundColor:"yellow"}} control={<Radio size="small"  />} label="Y" />
          <FormControlLabel value="b" sx={{backgroundColor:"blue"}} control={<Radio size="small"  />} label="B" />
          <FormControlLabel value="g" sx={{backgroundColor:"green"}} control={<Radio size="small"  />} label="G" />
          <FormControlLabel value=""  control={<Radio size="small"  />} label="Null" />
        </RadioGroup>

        

    </FormControl>
      </ListItem>

      <ListItem>
      <FormControl component="fieldset">
      <FormLabel component="legend">LED Indicator 4</FormLabel>
      <RadioGroup
       row
          aria-labelledby="demo-error-radios"
          name="quiz"
          value={data[side].led4}
          onChange={(e)=>{changeDataArray(side,{led4:e.target.value})}}
        >
<FormControlLabel value="r" sx={{backgroundColor:"red"}} control={<Radio  size="small"  ></Radio>} label="R" />
          <FormControlLabel value="y" sx={{backgroundColor:"yellow"}} control={<Radio size="small"  />} label="Y" />
          <FormControlLabel value="b" sx={{backgroundColor:"blue"}} control={<Radio size="small"  />} label="B" />
          <FormControlLabel value="g" sx={{backgroundColor:"green"}} control={<Radio size="small"  />} label="G" />
          <FormControlLabel value=""  control={<Radio size="small"  />} label="Null" />
        </RadioGroup>

        

    </FormControl>
      </ListItem>

      <ListItem>
      <FormControl component="fieldset">
      <FormLabel component="legend">LED Indicator 6</FormLabel>
      <RadioGroup
       row
          aria-labelledby="demo-error-radios"
          name="quiz"
          value={data[side].led6}
          onChange={(e)=>{changeDataArray(side,{led6:e.target.value})}}
        >
<FormControlLabel value="r" sx={{backgroundColor:"red"}} control={<Radio  size="small"  ></Radio>} label="R" />
          <FormControlLabel value="y" sx={{backgroundColor:"yellow"}} control={<Radio size="small"  />} label="Y" />
          <FormControlLabel value="b" sx={{backgroundColor:"blue"}} control={<Radio size="small"  />} label="B" />
          <FormControlLabel value="g" sx={{backgroundColor:"green"}} control={<Radio size="small"  />} label="G" />
          <FormControlLabel value=""  control={<Radio size="small"  />} label="Null" />
        </RadioGroup>

        

    </FormControl>
      </ListItem>

      <ListItem>
      <FormControl component="fieldset">
      <FormLabel component="legend">LED Indicator 7</FormLabel>
      <RadioGroup
       row
          aria-labelledby="demo-error-radios"
          name="quiz"
          value={data[side].led7}
          onChange={(e)=>{changeDataArray(side,{led7:e.target.value})}}
        >
         <FormControlLabel value="r" sx={{backgroundColor:"red"}} control={<Radio  size="small"  ></Radio>} label="R" />
          <FormControlLabel value="y" sx={{backgroundColor:"yellow"}} control={<Radio size="small"  />} label="Y" />
          <FormControlLabel value="b" sx={{backgroundColor:"blue"}} control={<Radio size="small"  />} label="B" />
          <FormControlLabel value="g" sx={{backgroundColor:"green"}} control={<Radio size="small"  />} label="G" />
          <FormControlLabel value=""  control={<Radio size="small"  />} label="Null" />
        </RadioGroup>

        

    </FormControl>
      </ListItem>

      <ListItem>
      <FormControl component="fieldset">
      <FormLabel component="legend">LED Indicator 9</FormLabel>
      <RadioGroup
       row
          aria-labelledby="demo-error-radios"
          name="quiz"
          value={data[side].led9}
          onChange={(e)=>{changeDataArray(side,{led9:e.target.value})}}
        >
          <FormControlLabel value="r" sx={{backgroundColor:"red"}} control={<Radio  size="small"  ></Radio>} label="R" />
          <FormControlLabel value="y" sx={{backgroundColor:"yellow"}} control={<Radio size="small"  />} label="Y" />
          <FormControlLabel value="b" sx={{backgroundColor:"blue"}} control={<Radio size="small"  />} label="B" />
          <FormControlLabel value="g" sx={{backgroundColor:"green"}} control={<Radio size="small"  />} label="G" />
          <FormControlLabel value=""  control={<Radio size="small"  />} label="Null" />
        </RadioGroup>

        

    </FormControl>
      </ListItem>
  </>:<></>
  
  
}
      </>:<></>
        }
      
{
  next=="3"?<> {/* option 4 */}
  <ListItem>
  <FormControl component="fieldset">
  {/* <FormLabel component="legend">keyboard 10-12</FormLabel> */}
  <FormGroup aria-label="position" row>
    
    
    <FormControlLabel
      value="end"
     
      control={<Checkbox 
        checked={data[side].button_10_to_12_q}
               onChange={(e)=>{changeDataArray(side,{button_10_to_12_q:e.target.checked})}}  />}
      label="keyboard 10-12"  />
  </FormGroup>
</FormControl>
  </ListItem>

{
  data[side].button_10_to_12_q?<>
  
  <ListItem>
      <FormControl component="fieldset">
      <FormLabel component="legend">LED Indicator 10</FormLabel>
      <RadioGroup
       row
          aria-labelledby="demo-error-radios"
          name="quiz"
          value={data[side].led10}
          onChange={(e)=>{changeDataArray(side,{led10:e.target.value})}}
        >
         <FormControlLabel value="r" sx={{backgroundColor:"red"}} control={<Radio  size="small"  ></Radio>} label="R" />
          <FormControlLabel value="y" sx={{backgroundColor:"yellow"}} control={<Radio size="small"  />} label="Y" />
          <FormControlLabel value="b" sx={{backgroundColor:"blue"}} control={<Radio size="small"  />} label="B" />
          <FormControlLabel value="g" sx={{backgroundColor:"green"}} control={<Radio size="small"  />} label="G" />
          <FormControlLabel value=""  control={<Radio size="small"  />} label="Null" />
        </RadioGroup>

        

    </FormControl>
      </ListItem>
  <ListItem>
      <FormControl component="fieldset">
      <FormLabel component="legend">LED Indicator 11</FormLabel>
      <RadioGroup
       row
          aria-labelledby="demo-error-radios"
          name="quiz"
          value={data[side].led11}
               onChange={(e)=>{changeDataArray(side,{led11:e.target.value})}}
        >
         <FormControlLabel value="r" sx={{backgroundColor:"red"}} control={<Radio  size="small"  ></Radio>} label="R" />
          <FormControlLabel value="y" sx={{backgroundColor:"yellow"}} control={<Radio size="small"  />} label="Y" />
          <FormControlLabel value="b" sx={{backgroundColor:"blue"}} control={<Radio size="small"  />} label="B" />
          <FormControlLabel value="g" sx={{backgroundColor:"green"}} control={<Radio size="small"  />} label="G" />
          <FormControlLabel value=""  control={<Radio size="small"  />} label="Null" />
        </RadioGroup>

        

    </FormControl>
      </ListItem>
  <ListItem>
      <FormControl component="fieldset">
      <FormLabel component="legend">LED Indicator 12</FormLabel>
      <RadioGroup
       row
          aria-labelledby="demo-error-radios"
          name="quiz"
          value={data[side].led12}
          onChange={(e)=>{changeDataArray(side,{led12:e.target.value})}}
        >
         <FormControlLabel value="r" sx={{backgroundColor:"red"}} control={<Radio  size="small"  ></Radio>} label="R" />
          <FormControlLabel value="y" sx={{backgroundColor:"yellow"}} control={<Radio size="small"  />} label="Y" />
          <FormControlLabel value="b" sx={{backgroundColor:"blue"}} control={<Radio size="small"  />} label="B" />
          <FormControlLabel value="g" sx={{backgroundColor:"green"}} control={<Radio size="small"  />} label="G" />
          <FormControlLabel value=""  control={<Radio size="small"  />} label="Null" />
        </RadioGroup>

        

    </FormControl>
      </ListItem>



      </>:<></>
}



  {/* option 4 */}
  <ListItem>
  <FormControl component="fieldset">
  {/* <FormLabel component="legend">keyboard 13-15 </FormLabel> */}
  <FormGroup aria-label="position" row>
    

    <FormControlLabel
      value="end"
     
      control={<Checkbox 
        checked={data[side].button_13_to_15_q}
        onChange={(e)=>{changeDataArray(side,{button_13_to_15_q:e.target.checked})}} 
        />}
      label="keyboard 13-15"
    
    />
  </FormGroup>
</FormControl>
  </ListItem>
  {
  data[side].button_13_to_15_q?<>
  
  <ListItem>
      <FormControl component="fieldset">
      <FormLabel component="legend">LED Indicator 13</FormLabel>
      <RadioGroup
       row
          aria-labelledby="demo-error-radios"
          name="quiz"
          value={data[side].led13}
          onChange={(e)=>{changeDataArray(side,{led13:e.target.value})}}
        >
         <FormControlLabel value="r" sx={{backgroundColor:"red"}} control={<Radio  size="small"  ></Radio>} label="R" />
          <FormControlLabel value="y" sx={{backgroundColor:"yellow"}} control={<Radio size="small"  />} label="Y" />
          <FormControlLabel value="b" sx={{backgroundColor:"blue"}} control={<Radio size="small"  />} label="B" />
          <FormControlLabel value="g" sx={{backgroundColor:"green"}} control={<Radio size="small"  />} label="G" />
          <FormControlLabel value=""  control={<Radio size="small"  />} label="Null" />
        </RadioGroup>

        

    </FormControl>
      </ListItem>
  <ListItem>
      <FormControl component="fieldset">
      <FormLabel component="legend">LED Indicator 14</FormLabel>
      <RadioGroup
       row
          aria-labelledby="demo-error-radios"
          name="quiz"
          value={data[side].led14}
               onChange={(e)=>{changeDataArray(side,{led14:e.target.value})}}
        >
         <FormControlLabel value="r" sx={{backgroundColor:"red"}} control={<Radio  size="small"  ></Radio>} label="R" />
          <FormControlLabel value="y" sx={{backgroundColor:"yellow"}} control={<Radio size="small"  />} label="Y" />
          <FormControlLabel value="b" sx={{backgroundColor:"blue"}} control={<Radio size="small"  />} label="B" />
          <FormControlLabel value="g" sx={{backgroundColor:"green"}} control={<Radio size="small"  />} label="G" />
          <FormControlLabel value=""  control={<Radio size="small"  />} label="Null" />
        </RadioGroup>

        

    </FormControl>
      </ListItem>
  <ListItem>
      <FormControl component="fieldset">
      <FormLabel component="legend">LED Indicator 15</FormLabel>
      <RadioGroup
       row
          aria-labelledby="demo-error-radios"
          name="quiz"
          value={data[side].led15}
          onChange={(e)=>{changeDataArray(side,{led15:e.target.value})}}
        >
         <FormControlLabel value="r" sx={{backgroundColor:"red"}} control={<Radio  size="small"  ></Radio>} label="R" />
          <FormControlLabel value="y" sx={{backgroundColor:"yellow"}} control={<Radio size="small"  />} label="Y" />
          <FormControlLabel value="b" sx={{backgroundColor:"blue"}} control={<Radio size="small"  />} label="B" />
          <FormControlLabel value="g" sx={{backgroundColor:"green"}} control={<Radio size="small"  />} label="G" />
          <FormControlLabel value=""  control={<Radio size="small"  />} label="Null" />
        </RadioGroup>

        

    </FormControl>
      </ListItem>



      </>:<></>
}



  {/* option 4 */}
 

</>:<></>


}



{
  next=="4"?<>  <ListItem>
  
  <FormControl fullWidth sx={{ m: 1, minWidth: 120 }}>
      <InputLabel htmlFor="grouped-select">keyboard 16-18 </InputLabel>
      <Select defaultValue="" id="grouped-select" label="keyboard 16-18"
      value={data[side].button_16_to_18_position}
      onChange={(e)=>{changeDataArray(side,{button_16_to_18_position:e.target.value})}}
      >
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        <ListSubheader>Position's</ListSubheader>

  
        <MenuItem   disabled={data[side].jonstick_htl_q===""?false:data[side].jonstick_htl_q==="B"?true:false} value="a"><table> <tr><td>Position A</td><td><img src={"/pics/side/keyboard_16_17_18/pos_a.png"} style={{height:"80px"}} /></td></tr> </table> </MenuItem>
        <MenuItem   disabled={data[side].jonstick_htl_q===""?false:data[side].jonstick_htl_q==="A"?true:false} value="c"><table> <tr><td>Position C</td><td><img src={"/pics/side/keyboard_16_17_18/pos_c.png"} style={{height:"80px"}} /></td></tr> </table></MenuItem>
        <MenuItem   disabled={data[side].jonstick_htl_q===""?false:data[side].jonstick_htl_q==="A"?true:false} value="d"><table> <tr><td>Position D</td><td><img src={"/pics/side/keyboard_16_17_18/pos_d.png"} style={{height:"80px"}} /></td></tr> </table></MenuItem>


  
    
      </Select>
    </FormControl>

    </ListItem>
  


  {
    data[side].button_16_to_18_position !==""?<>  <ListItem>
    <FormControl component="fieldset">
    <FormLabel component="legend">LED Indicator 16</FormLabel>
    <RadioGroup
     row
        aria-labelledby="demo-error-radios"
        name="quiz"
        value={data[side].led16}
        onChange={(e)=>{changeDataArray(side,{led16:e.target.value})}}
      >
       <FormControlLabel value="r" sx={{backgroundColor:"red"}} control={<Radio  size="small"  ></Radio>} label="R" />
        <FormControlLabel value="y" sx={{backgroundColor:"yellow"}} control={<Radio size="small"  />} label="Y" />
        <FormControlLabel value="b" sx={{backgroundColor:"blue"}} control={<Radio size="small"  />} label="B" />
        <FormControlLabel value="g" sx={{backgroundColor:"green"}} control={<Radio size="small"  />} label="G" />
        <FormControlLabel value=""  control={<Radio size="small"  />} label="Null" />
      </RadioGroup>

      

  </FormControl>
    </ListItem>
<ListItem>
    <FormControl component="fieldset">
    <FormLabel component="legend">LED Indicator 17</FormLabel>
    <RadioGroup
     row
        aria-labelledby="demo-error-radios"
        name="quiz"
        value={data[side].led17}
             onChange={(e)=>{changeDataArray(side,{led17:e.target.value})}}
      >
       <FormControlLabel value="r" sx={{backgroundColor:"red"}} control={<Radio  size="small"  ></Radio>} label="R" />
        <FormControlLabel value="y" sx={{backgroundColor:"yellow"}} control={<Radio size="small"  />} label="Y" />
        <FormControlLabel value="b" sx={{backgroundColor:"blue"}} control={<Radio size="small"  />} label="B" />
        <FormControlLabel value="g" sx={{backgroundColor:"green"}} control={<Radio size="small"  />} label="G" />
        <FormControlLabel value=""  control={<Radio size="small"  />} label="Null" />
      </RadioGroup>

      

  </FormControl>
    </ListItem>
<ListItem>
    <FormControl component="fieldset">
    <FormLabel component="legend">LED Indicator 18</FormLabel>
    <RadioGroup
     row
        aria-labelledby="demo-error-radios"
        name="quiz" 
        value={data[side].led18}
        onChange={(e)=>{changeDataArray(side,{led18:e.target.value})}}
      >
       <FormControlLabel  value="r" sx={{backgroundColor:"red"}} control={<Radio  size="small"  ></Radio>} label="R" />
        <FormControlLabel value="y" sx={{backgroundColor:"yellow"}} control={<Radio size="small"  />} label="Y" />
        <FormControlLabel value="b" sx={{backgroundColor:"blue"}} control={<Radio size="small"  />} label="B" />
        <FormControlLabel value="g" sx={{backgroundColor:"green"}} control={<Radio size="small"  />} label="G" />
        <FormControlLabel value=""  control={<Radio size="small"  />} label="Null" />
      </RadioGroup>

      

  </FormControl>
    </ListItem></>:<></>
  }

  {/* option  */}


  </>:   <></>
}



{
  next=="5"?<>
  {/* option 4 */}

<ListItem>
  <FormControl component="fieldset">
  {/* <FormLabel component="legend">keyboard 19-21 </FormLabel> */}
  <FormGroup aria-label="position" row>
    
    
    <FormControlLabel
      
     
      control={<Checkbox 
        checked={data[side].button_19_to_21_q }
        
      onChange={(e)=>{changeDataArray(side,{button_19_to_21_q:e.target.checked})}}  />}
      label="keyboard 19-21"
    />
  </FormGroup>
</FormControl>
  </ListItem>
  {
    data[side].button_19_to_21_q ?<>  <ListItem>
    <FormControl component="fieldset">
    <FormLabel component="legend">LED Indicator 19</FormLabel>
    <RadioGroup
     row
        aria-labelledby="demo-error-radios"
       
        value={data[side].led19}
        onChange={(e)=>{changeDataArray(side,{led19:e.target.value})}}
      >
       <FormControlLabel value="r" sx={{backgroundColor:"red"}} control={<Radio  size="small"  ></Radio>} label="R" />
        <FormControlLabel value="y" sx={{backgroundColor:"yellow"}} control={<Radio size="small"  />} label="Y" />
        <FormControlLabel value="b" sx={{backgroundColor:"blue"}} control={<Radio size="small"  />} label="B" />
        <FormControlLabel value="g" sx={{backgroundColor:"green"}} control={<Radio size="small"  />} label="G" />
        <FormControlLabel value=""  control={<Radio size="small"  />} label="Null" />
      </RadioGroup>

      

  </FormControl>
    </ListItem>
<ListItem>
    <FormControl component="fieldset">
    <FormLabel component="legend">LED Indicator 20</FormLabel>
    <RadioGroup
     row
        aria-labelledby="demo-error-radios"
        name="quiz"
        value={data[side].led20}
             onChange={(e)=>{changeDataArray(side,{led20:e.target.value})}}
      >
       <FormControlLabel value="r" sx={{backgroundColor:"red"}} control={<Radio  size="small"  ></Radio>} label="R" />
        <FormControlLabel value="y" sx={{backgroundColor:"yellow"}} control={<Radio size="small"  />} label="Y" />
        <FormControlLabel value="b" sx={{backgroundColor:"blue"}} control={<Radio size="small"  />} label="B" />
        <FormControlLabel value="g" sx={{backgroundColor:"green"}} control={<Radio size="small"  />} label="G" />
        <FormControlLabel value=""  control={<Radio size="small"  />} label="Null" />
      </RadioGroup>

      

  </FormControl>
    </ListItem>
<ListItem>
    <FormControl component="fieldset">
    <FormLabel component="legend">LED Indicator 21</FormLabel>
    <RadioGroup
     row
        aria-labelledby="demo-error-radios"
        name="quiz" 
        value={data[side].led21}
        onChange={(e)=>{changeDataArray(side,{led21:e.target.value})}}
      >
       <FormControlLabel  value="r" sx={{backgroundColor:"red"}} control={<Radio  size="small"  ></Radio>} label="R" />
        <FormControlLabel value="y" sx={{backgroundColor:"yellow"}} control={<Radio size="small"  />} label="Y" />
        <FormControlLabel value="b" sx={{backgroundColor:"blue"}} control={<Radio size="small"  />} label="B" />
        <FormControlLabel value="g" sx={{backgroundColor:"green"}} control={<Radio size="small"  />} label="G" />
        <FormControlLabel value=""  control={<Radio size="small"  />} label="Null" />
      </RadioGroup>

      

  </FormControl>
    </ListItem></>:<></>
  }
  </>:<></>
}


{
  next=="6"?<>
  {/* extra_switch_op_q */}
  <ListItem>
    <FormControl component="fieldset">
    <FormLabel component="legend">Roller Wheel options</FormLabel>
    <RadioGroup
     row
        aria-labelledby="demo-error-radios"
        name="quiz" 
        value={data[side].roller_htw}
        onChange={(e)=>{changeDataArray(side,{roller_htw:e.target.value})}}
      >
        <table>
          <tr>
            <td>
             <FormControlLabel disabled={data[side].extra_switch_op_q=="B"?true:data[side].push_button_switch=="no"?false:true}  value="Roller_HTW_Friction"  control={<Radio  size="small"  ></Radio>} label="Roller HTW Friction" /></td>
            <td><img src={"/pics/side/rocker_friction.png"} style={{height:"80px"}} /></td>
          </tr>
          <tr>
            <td><FormControlLabel  disabled={data[side].extra_switch_op_q=="B"?true:data[side].push_button_switch=="no"?false:true} value="Roller_HTW_Spring_Return"  control={<Radio size="small"  />} label="Roller HTW Spring Return" /></td>
            <td><img src={"/pics/side/rocker_spring_return.png"} style={{height:"80px"}} /></td>
          </tr>
          <tr>
            <td><FormControlLabel value=""  control={<Radio size="small"  />} label="Null" /></td>
           
          </tr>
        </table>
      
        
      
      </RadioGroup>

      

  </FormControl>
    </ListItem>

    <ListItem>
    <FormControl component="fieldset">
    <FormLabel component="legend">LED Indicator options</FormLabel>
    <RadioGroup
     row
        aria-labelledby="demo-error-radios"
        name="quiz" 
        value={data[side].led_indicator_option}
        onChange={(e)=>{changeDataArray(side,{led_indicator_option:e.target.value})}}
      >
        <table>
          <tr>
            <td> <FormControlLabel  value="yes"  control={<Radio  size="small"  ></Radio>} label="Yes" /></td>
            <td><FormControlLabel value="no"  control={<Radio size="small"  />} label="No" /></td>
           
          </tr>
        
        </table>
      
        
      
      </RadioGroup>

      

  </FormControl>
    </ListItem>
  </>:<></>

}
{
  next=="7"?<>
  
 
  <ListItem>
          {/* <image sx={{ width: 500, height: 450 }} src="/pics/Joystick_HTL/2.png" /> */}
        <FormControl fullWidth sx={{ m: 1, minWidth: 120 }}>
            <InputLabel htmlFor="grouped-select">Extra switch options</InputLabel>
            <Select defaultValue="" 
             value={data[side].extra_switch_op_selected}
               onChange={(e)=>{
                var s=e.target.value;
               var d="";
                if(s=="")
                {

                }else{
                  if(s=="A1")
                {
                  d="A"

                }else if(s=="A2")
                {
                  d="A"
                }else if(s=="B1")
                {
                  d="B"

                }else if(s=="B2")
                {
                  d="B"
                }else if(s=="C1")
                {
                  d="C"
                }else if(s=="C2")
                {
                  d="C"
                }

                }
                changeDataArray(side,{extra_switch_op_selected:e.target.value,extra_switch_op_q:d})
                }} id="grouped-select" label="Extra switch options">
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <ListSubheader><table> <tr><td>Position A</td></tr>
              <tr> 
                <td><img src='/pics/side/rocker_switch_2_way/a.png' height={"80px"} /> </td></tr> </table>    </ListSubheader>
              <MenuItem value={"A1"} ><img src={"/pics/side/rocker_switch_2_way/two_way_1.png"} style={{height:"80px"}} /></MenuItem>
              <MenuItem value={"A2"}><img src={"/pics/side/rocker_switch_2_way/two_way_2.png"} style={{height:"80px"}} /></MenuItem>
              
              <ListSubheader><table> <tr><td>Position B</td></tr>
              <tr>
                <td><img src='/pics/side/rocker_switch_2_way/b.png' height={"80px"} /> </td></tr> </table>  </ListSubheader>
                <MenuItem   disabled={data[side].roller_htw !=""?true:data[side].push_button_switch=="no"?false:true}  value={"B1"} ><img src={"/pics/side/rocker_switch_2_way/two_way_1.png"} style={{height:"80px"}} /></MenuItem>
                <MenuItem disabled={data[side].roller_htw !=""?true:data[side].push_button_switch=="no"?false:true} value={"B2"}><img src={"/pics/side/rocker_switch_2_way/two_way_2.png"} style={{height:"80px"}} /></MenuItem>
               <ListSubheader><table> <tr><td>Position C</td></tr>
              <tr>
                <td><img src='/pics/side/rocker_switch_2_way/c.png' height={"80px"} /> </td></tr> </table>  </ListSubheader>
                <MenuItem disabled={data[side].button_19_to_21_q==true?true:false} value={"C1"} ><img src={"/pics/side/rocker_switch_2_way/two_way_1.png"} style={{height:"80px"}} /></MenuItem>
              <MenuItem disabled={data[side].button_19_to_21_q==true?true:false}  value={"C2"}><img src={"/pics/side/rocker_switch_2_way/two_way_2.png"} style={{height:"80px"}} /></MenuItem>
            </Select>
          </FormControl>
    
          </ListItem>

    <ListItem>
    <FormControl component="fieldset">
    <FormLabel component="legend">Push button switch </FormLabel>
    <RadioGroup
     row
        aria-labelledby="demo-error-radios"
        name="quiz" 
        value={data[side].push_button_switch}
        onChange={(e)=>{changeDataArray(side,{push_button_switch:e.target.value})}}
      >
        <table>
          <tr>
            <td> <FormControlLabel  value="yes"  disabled={data[side].roller_htw !=""?true:data[side].extra_switch_op_q=="B"?true:false}  control={<Radio  size="small"  ></Radio>} label="Yes" /></td>
            <td><FormControlLabel value="no"  control={<Radio size="small"  />} label="No" /></td>
            <td><img src={"/pics/side/rocker_switch_2_way/push_button_at_b.png"} style={{height:"80px"}} /></td>
           
          </tr>
        
        </table>
      
        
      
      </RadioGroup>

      

  </FormControl>
    </ListItem>

    <ListItem>
    <FormControl component="fieldset">
    <FormLabel component="legend">Customer designed options </FormLabel>
    <RadioGroup
     row
        aria-labelledby="demo-error-radios"
       
        value={data[side].customer_logo}
        onChange={
          (e)=>{
            changeDataArray(3,{customer_logo:e.target.value})
          
          }
        
        
        }
      >
        <table>
          <tr>
            <td> <FormControlLabel  value="false"  control={<Radio  size="small"  ></Radio>} label="Excidor designed decal" /></td>
            <td><img src={"/pics/side/rocker_switch_2_way/excidor_logo.png"} style={{height:"80px"}} /></td>
           
          </tr>
          <tr>
            <td> <FormControlLabel  value="true"  control={<Radio  size="small"  ></Radio>} label="Customer designed decal" /></td>
            <td><img src={"/pics/side/rocker_switch_2_way/custom_logo.png"} style={{height:"80px"}} /></td>
           
          </tr>
        
        </table>
      
        
      
      </RadioGroup>

      

  </FormControl>
    </ListItem>
  </>:<></>

}
      
<ListItem>
<center style={{marginTop:"20px"}}>
       {
        next=="1"?<></>:<>
       

 <Button style={{marginRight:"20px",marginLeft:"20px",marginBottom:"10px"}} variant="contained" startIcon={<ArrowBackIosNewIcon />} onClick={()=>{
          if(next=="2")
          {
            setNext("1")
          }else  if(next=="3")
          {
            setNext("2")
          }else  if(next=="4")
          {
            setNext("3")
          }else  if(next=="5")
          {
            setNext("4")
          }else  if(next=="6")
          {
            setNext("5")
          }else  if(next=="7")
          {
            setNext("6")
          }else  if(next=="8")
          {
            setNext("7")
          }
          
        }} size="small"> Back </Button></>
       }


        <Button variant="contained" style={{marginRight:"20px",marginLeft:"20px",marginBottom:"10px"}} endIcon={<NavigateNextIcon />} onClick={()=>{
            if(next=="1")
            {
              setNext("2")
            }else  if(next=="2")
            {
              setNext("3")
            }else  if(next=="3")
            {
              setNext("4")
            }else  if(next=="4")
            {
              setNext("5")
            }else  if(next=="5")
            {
              setNext("6")
            }else  if(next=="6")
            {
              setNext("7")
            }else if(next=="7")
            {
              add();


            }

        }} size="small"> {next=="7"?"Quote":"Next"} </Button>



       
        </center>
    
      
      
        </ListItem>
        <ListItem>
        <Button variant="contained" style={{marginLeft:"20px",marginBottom:"10px"}} onClick={()=>{
          
          
            
            if (window.confirm('Are you sure you want to Clear & Restart?')) {
             
              setData(array);
              setNext("1")
            }

        }} size="small">Clear & Restart</Button>
        </ListItem>

    </List>

   
      
    
      
    
    

  </Grid>


    <Grid sx={{marginBottom:"1000px"}}   xs={3}>
    
    <>
      
   
  
   {
    data[0].armrest==""?<></>:<><img  src={data[0].armrest=="2LK"?"/pics/Armrest_bracket/ab1.PNG":"/pics/Armrest_bracket/ab2.PNG"}   className="under"  /></>
   }
    
    
  
    
    
    <img src="/pics/base.png"    className="under"  /> 
  
  {
    data[0].jonstick_htl_q==""?<></>:<>
    <img src={data[0].jonstick_htl_q=="A"?"/pics/Joystick_HTL/xj1a.PNG":"/pics/Joystick_HTL/xj1b.png"}   className="under"  /> 
    
    </>
  }
    
    {  data[0].jonstick_otto=="std"?
    
      <img src={"/pics/Joy_Stick_Otto/j1.PNG"}    className="under"  />:<></>
  
    }
    {  data[0].jonstick_otto=="extended"?
    
      <img src={"/pics/Joy_Stick_Otto/j2.PNG"}   className="under"  />:<></>
  
    }
    {  data[0].jonstick_otto=="5-button"?
    
      <img src={"/pics/Joy_Stick_Otto/j3.PNG"}   className="under"  />:<></>
    
      
  
    }
  
    {data[0].button_1_to_9_q?<><img src={"/pics/9_button_keyboard_1_to_9/k1_9a.PNG"}   className="under"  /></>:<></>}
    {/* Led 1 */}
    {data[0].led1=="r"?<><img src={"/pics/9_button_keyboard_1_to_9/1r.png"}   className="under"  /></>:<></>}
    {data[0].led1=="y"?<><img src={"/pics/9_button_keyboard_1_to_9/1y.png"}   className="under"  /></>:<></>}
    {data[0].led1=="b"?<><img src={"/pics/9_button_keyboard_1_to_9/1b.png"}   className="under"  /></>:<></>}
    {data[0].led1=="g"?<><img src={"/pics/9_button_keyboard_1_to_9/1g.png"}   className="under"  /></>:<></>}
    {/* Led 3 */}
    {data[0].led3=="r"?<><img src={"/pics/9_button_keyboard_1_to_9/2r.png"}   className="under"  /></>:<></>}
    {data[0].led3=="y"?<><img src={"/pics/9_button_keyboard_1_to_9/2y.png"}   className="under"  /></>:<></>}
    {data[0].led3=="b"?<><img src={"/pics/9_button_keyboard_1_to_9/2b.png"}   className="under"  /></>:<></>}
    {data[0].led3=="g"?<><img src={"/pics/9_button_keyboard_1_to_9/2g.png"}   className="under"  /></>:<></>}
    {/* Led 4 */}
    {data[0].led4=="r"?<><img src={"/pics/9_button_keyboard_1_to_9/3r.png"}   className="under"  /></>:<></>}
    {data[0].led4=="y"?<><img src={"/pics/9_button_keyboard_1_to_9/3y.png"}   className="under"  /></>:<></>}
    {data[0].led4=="b"?<><img src={"/pics/9_button_keyboard_1_to_9/3b.png"}   className="under"  /></>:<></>}
    {data[0].led4=="g"?<><img src={"/pics/9_button_keyboard_1_to_9/3g.png"}   className="under"  /></>:<></>}
  
      {/* Led 6 */}
    {data[0].led6=="r"?<><img src={"/pics/9_button_keyboard_1_to_9/4r.png"}   className="under"  /></>:<></>}
    {data[0].led6=="y"?<><img src={"/pics/9_button_keyboard_1_to_9/4y.png"}   className="under"  /></>:<></>}
    {data[0].led6=="b"?<><img src={"/pics/9_button_keyboard_1_to_9/4b.png"}   className="under"  /></>:<></>}
    {data[0].led6=="g"?<><img src={"/pics/9_button_keyboard_1_to_9/4g.png"}   className="under"  /></>:<></>}
    {/* Led 7 */}
    {data[0].led7=="r"?<><img src={"/pics/9_button_keyboard_1_to_9/5r.png"}   className="under"  /></>:<></>}
    {data[0].led7=="y"?<><img src={"/pics/9_button_keyboard_1_to_9/5y.png"}   className="under"  /></>:<></>}
    {data[0].led7=="b"?<><img src={"/pics/9_button_keyboard_1_to_9/5b.png"}   className="under"  /></>:<></>}
    {data[0].led7=="g"?<><img src={"/pics/9_button_keyboard_1_to_9/5g.png"}   className="under"  /></>:<></>}
  
      {/* Led 9 */}
      {data[0].led9=="r"?<><img src={"/pics/9_button_keyboard_1_to_9/6r.png"}   className="under"  /></>:<></>}
    {data[0].led9=="y"?<><img src={"/pics/9_button_keyboard_1_to_9/6y.png"}   className="under"  /></>:<></>}
    {data[0].led9=="b"?<><img src={"/pics/9_button_keyboard_1_to_9/6b.png"}   className="under"  /></>:<></>}
    {data[0].led9=="g"?<><img src={"/pics/9_button_keyboard_1_to_9/6g.png"}   className="under"  /></>:<></>}
  
  
  
      {/* Led 10 */}
    {data[0].led10=="r"?<><img src={"/pics/3_button_keyboard_10_to_12/10r.png"}   className="under"  /></>:<></>}
    {data[0].led10=="y"?<><img src={"/pics/3_button_keyboard_10_to_12/10y.png"}   className="under"  /></>:<></>}
    {data[0].led10=="b"?<><img src={"/pics/3_button_keyboard_10_to_12/10b.png"}   className="under"  /></>:<></>}
    {data[0].led10=="g"?<><img src={"/pics/3_button_keyboard_10_to_12/10g.png"}   className="under"  /></>:<></>}
  
  
  
      {/* Led 11 */}
    {data[0].led11=="r"?<><img src={"/pics/3_button_keyboard_10_to_12/11r.png"}   className="under"  /></>:<></>}
    {data[0].led11=="y"?<><img src={"/pics/3_button_keyboard_10_to_12/11y.png"}   className="under"  /></>:<></>}
    {data[0].led11=="b"?<><img src={"/pics/3_button_keyboard_10_to_12/11b.png"}   className="under"  /></>:<></>}
    {data[0].led11=="g"?<><img src={"/pics/3_button_keyboard_10_to_12/11g.png"}   className="under"  /></>:<></>}
  
  
  
      {/* Led 12 */}
      {data[0].led12=="r"?<><img src={"/pics/3_button_keyboard_10_to_12/12r.png"}   className="under"  /></>:<></>}
    {data[0].led12=="y"?<><img src={"/pics/3_button_keyboard_10_to_12/12y.png"}   className="under"  /></>:<></>}
    {data[0].led12=="b"?<><img src={"/pics/3_button_keyboard_10_to_12/12b.png"}   className="under"  /></>:<></>}
    {data[0].led12=="g"?<><img src={"/pics/3_button_keyboard_10_to_12/12g.png"}   className="under"  /></>:<></>}
  
  
      {/* Led 13 */}
      {data[0].led13=="r"?<><img src={"/pics/3_button_keyboard_13_15_pos_B/13r.png"}   className="under"  /></>:<></>}
    {data[0].led13=="y"?<><img src={"/pics/3_button_keyboard_13_15_pos_B/13y.png"}   className="under"  /></>:<></>}
    {data[0].led13=="b"?<><img src={"/pics/3_button_keyboard_13_15_pos_B/13b.png"}   className="under"  /></>:<></>}
    {data[0].led13=="g"?<><img src={"/pics/3_button_keyboard_13_15_pos_B/13g.png"}   className="under"  /></>:<></>}
  
  
  
      {/* Led 14 */}
    {data[0].led14=="r"?<><img src={"/pics/3_button_keyboard_13_15_pos_B/14r.png"}   className="under"  /></>:<></>}
    {data[0].led14=="y"?<><img src={"/pics/3_button_keyboard_13_15_pos_B/14y.png"}   className="under"  /></>:<></>}
    {data[0].led14=="b"?<><img src={"/pics/3_button_keyboard_13_15_pos_B/14b.png"}   className="under"  /></>:<></>}
    {data[0].led14=="g"?<><img src={"/pics/3_button_keyboard_13_15_pos_B/14g.png"}   className="under"  /></>:<></>}
  
  
  
      {/* Led 15 */}
    {data[0].led15=="r"?<><img src={"/pics/3_button_keyboard_13_15_pos_B/15r.png"}   className="under"  /></>:<></>}
    {data[0].led15=="y"?<><img src={"/pics/3_button_keyboard_13_15_pos_B/15y.png"}   className="under"  /></>:<></>}
    {data[0].led15=="b"?<><img src={"/pics/3_button_keyboard_13_15_pos_B/15b.png"}   className="under"  /></>:<></>}
    {data[0].led15=="g"?<><img src={"/pics/3_button_keyboard_13_15_pos_B/15g.png"}   className="under"  /></>:<></>}
     
   
      {/* Led 16 pos a */}
    {data[0].led16=="r" && data[0].button_16_to_18_position=="a" ?<><img src={"/pics/3_button_keyboard_16_18/pos_a/1r.png"}   className="under"  /></>:<></>}
    {data[0].led16=="y" && data[0].button_16_to_18_position=="a"?<><img src={"/pics/3_button_keyboard_16_18/pos_a/1y.png"}   className="under"  /></>:<></>}
    {data[0].led16=="b" && data[0].button_16_to_18_position=="a"?<><img src={"/pics/3_button_keyboard_16_18/pos_a/1b.png"}   className="under"  /></>:<></>}
    {data[0].led16=="g" && data[0].button_16_to_18_position=="a"?<><img src={"/pics/3_button_keyboard_16_18/pos_a/1g.png"}   className="under"  /></>:<></>}
      
      {/* Led 17 pos a */}
    {data[0].led17=="r" && data[0].button_16_to_18_position=="a" ?<><img src={"/pics/3_button_keyboard_16_18/pos_a/2r.png"}   className="under"  /></>:<></>}
    {data[0].led17=="y" && data[0].button_16_to_18_position=="a"?<><img src={"/pics/3_button_keyboard_16_18/pos_a/2y.png"}   className="under"  /></>:<></>}
    {data[0].led17=="b" && data[0].button_16_to_18_position=="a"?<><img src={"/pics/3_button_keyboard_16_18/pos_a/2b.png"}   className="under"  /></>:<></>}
    {data[0].led17=="g" && data[0].button_16_to_18_position=="a"?<><img src={"/pics/3_button_keyboard_16_18/pos_a/2g.png"}   className="under"  /></>:<></>}
      
      {/* Led 18 pos a */}
    {data[0].led18=="r" && data[0].button_16_to_18_position=="a" ?<><img src={"/pics/3_button_keyboard_16_18/pos_a/3r.png"}   className="under"  /></>:<></>}
    {data[0].led18=="y" && data[0].button_16_to_18_position=="a"?<><img src={"/pics/3_button_keyboard_16_18/pos_a/3y.png"}   className="under"  /></>:<></>}
    {data[0].led18=="b" && data[0].button_16_to_18_position=="a"?<><img src={"/pics/3_button_keyboard_16_18/pos_a/3b.png"}   className="under"  /></>:<></>}
    {data[0].led18=="g" && data[0].button_16_to_18_position=="a"?<><img src={"/pics/3_button_keyboard_16_18/pos_a/3g.png"}   className="under"  /></>:<></>}
      
      {/* Led 16 pos c */}
    {data[0].led16=="r" && data[0].button_16_to_18_position=="c" ?<><img src={"/pics/3_button_keyboard_16_18/pos_c/1r.png"}   className="under"  /></>:<></>}
    {data[0].led16=="y" && data[0].button_16_to_18_position=="c"?<><img src={"/pics/3_button_keyboard_16_18/pos_c/1y.png"}   className="under"  /></>:<></>}
    {data[0].led16=="b" && data[0].button_16_to_18_position=="c"?<><img src={"/pics/3_button_keyboard_16_18/pos_c/1b.png"}   className="under"  /></>:<></>}
    {data[0].led16=="g" && data[0].button_16_to_18_position=="c"?<><img src={"/pics/3_button_keyboard_16_18/pos_c/1g.png"}   className="under"  /></>:<></>}
      
      {/* Led 17 pos c */}
    {data[0].led17=="r" && data[0].button_16_to_18_position=="c" ?<><img src={"/pics/3_button_keyboard_16_18/pos_c/2r.png"}   className="under"  /></>:<></>}
    {data[0].led17=="y" && data[0].button_16_to_18_position=="c"?<><img src={"/pics/3_button_keyboard_16_18/pos_c/2y.png"}   className="under"  /></>:<></>}
    {data[0].led17=="b" && data[0].button_16_to_18_position=="c"?<><img src={"/pics/3_button_keyboard_16_18/pos_c/2b.png"}   className="under"  /></>:<></>}
    {data[0].led17=="g" && data[0].button_16_to_18_position=="c"?<><img src={"/pics/3_button_keyboard_16_18/pos_c/2g.png"}   className="under"  /></>:<></>}
      
      {/* Led 18 pos c */}
    {data[0].led18=="r" && data[0].button_16_to_18_position=="c" ?<><img src={"/pics/3_button_keyboard_16_18/pos_c/3r.png"}   className="under"  /></>:<></>}
    {data[0].led18=="y" && data[0].button_16_to_18_position=="c"?<><img src={"/pics/3_button_keyboard_16_18/pos_c/3y.png"}   className="under"  /></>:<></>}
    {data[0].led18=="b" && data[0].button_16_to_18_position=="c"?<><img src={"/pics/3_button_keyboard_16_18/pos_c/3b.png"}   className="under"  /></>:<></>}
    {data[0].led18=="g" && data[0].button_16_to_18_position=="c"?<><img src={"/pics/3_button_keyboard_16_18/pos_c/3g.png"}   className="under"  /></>:<></>}
      
      {/* Led 16 pos d */}
    {data[0].led16=="r" && data[0].button_16_to_18_position=="d" ?<><img src={"/pics/3_button_keyboard_16_18/pos_d/1r.png"}   className="under"  /></>:<></>}
    {data[0].led16=="y" && data[0].button_16_to_18_position=="d"?<><img src={"/pics/3_button_keyboard_16_18/pos_d/1y.png"}   className="under"  /></>:<></>}
    {data[0].led16=="b" && data[0].button_16_to_18_position=="d"?<><img src={"/pics/3_button_keyboard_16_18/pos_d/1b.png"}   className="under"  /></>:<></>}
    {data[0].led16=="g" && data[0].button_16_to_18_position=="d"?<><img src={"/pics/3_button_keyboard_16_18/pos_d/1g.png"}   className="under"  /></>:<></>}
      
      {/* Led 17 pos d */}
    {data[0].led17=="r" && data[0].button_16_to_18_position=="d" ?<><img src={"/pics/3_button_keyboard_16_18/pos_d/2r.png"}   className="under"  /></>:<></>}
    {data[0].led17=="y" && data[0].button_16_to_18_position=="d"?<><img src={"/pics/3_button_keyboard_16_18/pos_d/2y.png"}   className="under"  /></>:<></>}
    {data[0].led17=="b" && data[0].button_16_to_18_position=="d"?<><img src={"/pics/3_button_keyboard_16_18/pos_d/2b.png"}   className="under"  /></>:<></>}
    {data[0].led17=="g" && data[0].button_16_to_18_position=="d"?<><img src={"/pics/3_button_keyboard_16_18/pos_d/2g.png"}   className="under"  /></>:<></>}
      
      {/* Led 18 pos d */}
    {data[0].led18=="r" && data[0].button_16_to_18_position=="d" ?<><img src={"/pics/3_button_keyboard_16_18/pos_d/3r.png"}   className="under"  /></>:<></>}
    {data[0].led18=="y" && data[0].button_16_to_18_position=="d"?<><img src={"/pics/3_button_keyboard_16_18/pos_d/3y.png"}   className="under"  /></>:<></>}
    {data[0].led18=="b" && data[0].button_16_to_18_position=="d"?<><img src={"/pics/3_button_keyboard_16_18/pos_d/3b.png"}   className="under"  /></>:<></>}
    {data[0].led18=="g" && data[0].button_16_to_18_position=="d"?<><img src={"/pics/3_button_keyboard_16_18/pos_d/3g.png"}   className="under"  /></>:<></>}
      
          {/* Led 19 */}
    {data[0].led19=="r" && data[0].button_19_to_21_q ?<><img src={"/pics/3_button_keyboard_19_21/1r.png"}   className="under"  /></>:<></>}
    {data[0].led19=="y" && data[0].button_19_to_21_q?<><img src={"/pics/3_button_keyboard_19_21/1y.png"}   className="under"  /></>:<></>}
    {data[0].led19=="b" && data[0].button_19_to_21_q?<><img src={"/pics/3_button_keyboard_19_21/1b.png"}   className="under"  /></>:<></>}
    {data[0].led19=="g" && data[0].button_19_to_21_q?<><img src={"/pics/3_button_keyboard_19_21/1g.png"}   className="under"  /></>:<></>}
          {/* Led 20 */}
    {data[0].led20=="r" && data[0].button_19_to_21_q ?<><img src={"/pics/3_button_keyboard_19_21/2r.png"}   className="under"  /></>:<></>}
    {data[0].led20=="y" && data[0].button_19_to_21_q?<><img src={"/pics/3_button_keyboard_19_21/2y.png"}   className="under"  /></>:<></>}
    {data[0].led20=="b" && data[0].button_19_to_21_q?<><img src={"/pics/3_button_keyboard_19_21/2b.png"}   className="under"  /></>:<></>}
    {data[0].led20=="g" && data[0].button_19_to_21_q?<><img src={"/pics/3_button_keyboard_19_21/2g.png"}   className="under"  /></>:<></>}
          {/* Led 21 */}
    {data[0].led21=="r" && data[0].button_19_to_21_q ?<><img src={"/pics/3_button_keyboard_19_21/3r.png"}   className="under"  /></>:<></>}
    {data[0].led21=="y" && data[0].button_19_to_21_q?<><img src={"/pics/3_button_keyboard_19_21/3y.png"}   className="under"  /></>:<></>}
    {data[0].led21=="b" && data[0].button_19_to_21_q?<><img src={"/pics/3_button_keyboard_19_21/3b.png"}   className="under"  /></>:<></>}
    {data[0].led21=="g" && data[0].button_19_to_21_q?<><img src={"/pics/3_button_keyboard_19_21/3g.png"}   className="under"  /></>:<></>}
      
  
  
  
  
  
    {data[0].button_10_to_12_q?<><img src={"/pics/3_button_keyboard_10_to_12/k10_12b.png"}   className="under"  /></>:<></>}
    {data[0].button_13_to_15_q?<><img src={"/pics/3_button_keyboard_13_15_pos_B/k13_15b.PNG"}   className="under"  /></>:<></>}
  
  
    {data[0].button_16_to_18_position=="a"?<>
    <img src={"/pics/3_button_keyboard_16_18/k16_18a.PNG"}   className="under"  />
    
    
    
    </>:<></>}
    {data[0].button_16_to_18_position=="c"?<><img src={"/pics/3_button_keyboard_16_18/k16_18c.PNG"}   className="under"  /></>:<></>}
    {data[0].button_16_to_18_position=="d"?<><img src={"/pics/3_button_keyboard_16_18/k16_18d.PNG"}   className="under"  /></>:<></>}
  
  {
    data[0].button_19_to_21_q?<><img src={"/pics/3_button_keyboard_19_21/k19_21a.PNG"}   className="under"  /></>:<></>
  }



  {
    data[0].roller_htw!=""?<><img src={"/pics/Roller_HTW/pic.PNG"}   className="under"  /></>:<></>
  }

  {
    data[0].extra_switch_op_q=="A"?<><img src={"/pics/Rocker_switch_2_way/RS2A.PNG"}   className="under"  /></>:<></>
  }
  {
    data[0].extra_switch_op_q=="B"?<><img src={"/pics/Rocker_switch_2_way/RS2B.PNG"}   className="under"  /></>:<></>
  }
  {
    data[0].extra_switch_op_q=="C"?<><img src={"/pics/Rocker_switch_2_way/RS2C.PNG"}   className="under"  /></>:<></>
  }
  {
    data[0].push_button_switch=="yes"?<><img src={"/pics/Push_button_switch/pba1.png"}   className="under"  /></>:<></>
  }
  {/*  LED Indicator */}
  {
    data[0].led_indicator_option=="yes"?<><img src={"/pics/led_Indicator/1.png"}   className="under"  /></>:<></>
  }
  
  {/* Logo */}
  {
    data[0].customer_logo=="true"?<><img src={"/pics/Customer_designed_decal/cd2.png"}   className="under"  /></>:<><img src={"/pics/Customer_designed_decal/cd1.png"}   className="under"  /> </>
  }

  
    </>
    </Grid>

    <Grid  xs={3}>
    <table>

      <tr>
        <td>
        <>
      
   
  
      {
       data[1].armrest==""?<></>:<><img src={data[1].armrest=="2LK"?"/pics/Armrest_bracket/ab1.PNG":"/pics/Armrest_bracket/ab2.PNG"}   className="under"  /></>
      }
       
       
     
       
       
       <img src="/pics/right/base.png"   className="under"  /> 
     
     {
       data[1].jonstick_htl_q==""?<></>:<>
       <img src={data[1].jonstick_htl_q=="A"?"/pics/right/Joystick_HTL/xj1a.PNG":"/pics/right/Joystick_HTL/xj1b.png"}   className="under"  /> 
       
       </>
     }
       
       {  data[1].jonstick_otto=="std"?
       
         <img src={"/pics/right/Joy_Stick_Otto/j1.PNG"}   className="under"  />:<></>
     
       }
       {  data[1].jonstick_otto=="extended"?
       
         <img src={"/pics/right/Joy_Stick_Otto/j2.PNG"}   className="under"  />:<></>
     
       }
       {  data[1].jonstick_otto=="5-button"?
       
         <img src={"/pics/right/Joy_Stick_Otto/j3.PNG"}   className="under"  />:<></>
       
         
     
       }
     
       {data[1].button_1_to_9_q?<><img src={"/pics/right/9_button_keyboard_1_to_9/k1_9a.PNG"}   className="under"  /></>:<></>}



       {/* Led 1 */}
     {data[1].led1=="r"?<><img src={"/pics/right/9_button_keyboard_1_to_9/1r.png"}   className="under"  /></>:<></>}
    {data[1].led1=="y"?<><img src={"/pics/right/9_button_keyboard_1_to_9/1y.png"}   className="under"  /></>:<></>}
    {data[1].led1=="b"?<><img src={"/pics/right/9_button_keyboard_1_to_9/1b.png"}   className="under"  /></>:<></>}
    {data[1].led1=="g"?<><img src={"/pics/right/9_button_keyboard_1_to_9/1g.png"}   className="under"  /></>:<></>}
       {/* Led 3 */}
       {data[1].led3=="r"?<><img src={"/pics/right/9_button_keyboard_1_to_9/2r.png"}   className="under"  /></>:<></>}
    {data[1].led3=="y"?<><img src={"/pics/right/9_button_keyboard_1_to_9/2y.png"}   className="under"  /></>:<></>}
    {data[1].led3=="b"?<><img src={"/pics/right/9_button_keyboard_1_to_9/2b.png"}   className="under"  /></>:<></>}
    {data[1].led3=="g"?<><img src={"/pics/right/9_button_keyboard_1_to_9/2g.png"}   className="under"  /></>:<></>}
       {/* Led 4 */}
       {data[1].led4=="r"?<><img src={"/pics/right/9_button_keyboard_1_to_9/3r.png"}   className="under"  /></>:<></>}
       {data[1].led4=="y"?<><img src={"/pics/right/9_button_keyboard_1_to_9/3y.png"}   className="under"  /></>:<></>}
       {data[1].led4=="b"?<><img src={"/pics/right/9_button_keyboard_1_to_9/3b.png"}   className="under"  /></>:<></>}
       {data[1].led4=="g"?<><img src={"/pics/right/9_button_keyboard_1_to_9/3g.png"}   className="under"  /></>:<></>}
     
         {/* Led 6 */}
       {data[1].led6=="r"?<><img src={"/pics/right/9_button_keyboard_1_to_9/4r.png"}   className="under"  /></>:<></>}
       {data[1].led6=="y"?<><img src={"/pics/right/9_button_keyboard_1_to_9/4y.png"}   className="under"  /></>:<></>}
       {data[1].led6=="b"?<><img src={"/pics/right/9_button_keyboard_1_to_9/4b.png"}   className="under"  /></>:<></>}
       {data[1].led6=="g"?<><img src={"/pics/right/9_button_keyboard_1_to_9/4g.png"}   className="under"  /></>:<></>}
       {/* Led 7 */}
       {data[1].led7=="r"?<><img src={"/pics/right/9_button_keyboard_1_to_9/5r.png"}   className="under"  /></>:<></>}
       {data[1].led7=="y"?<><img src={"/pics/right/9_button_keyboard_1_to_9/5y.png"}   className="under"  /></>:<></>}
       {data[1].led7=="b"?<><img src={"/pics/right/9_button_keyboard_1_to_9/5b.png"}   className="under"  /></>:<></>}
       {data[1].led7=="g"?<><img src={"/pics/right/9_button_keyboard_1_to_9/5g.png"}   className="under"  /></>:<></>}
     
         {/* Led 9 */}
       {data[1].led9=="r"?<><img src={"/pics/right/9_button_keyboard_1_to_9/6r.png"}   className="under"  /></>:<></>}
       {data[1].led9=="y"?<><img src={"/pics/right/9_button_keyboard_1_to_9/6y.png"}   className="under"  /></>:<></>}
       {data[1].led9=="b"?<><img src={"/pics/right/9_button_keyboard_1_to_9/6b.png"}   className="under"  /></>:<></>}
       {data[1].led9=="g"?<><img src={"/pics/right/9_button_keyboard_1_to_9/6g.png"}   className="under"  /></>:<></>}
     
     
     
         {/* Led 10 */}
       {data[1].led10=="r"?<><img src={"/pics/right/3_button_keyboard_10_to_12/10r.png"}   className="under"  /></>:<></>}
       {data[1].led10=="y"?<><img src={"/pics/right/3_button_keyboard_10_to_12/10y.png"}   className="under"  /></>:<></>}
       {data[1].led10=="b"?<><img src={"/pics/right/3_button_keyboard_10_to_12/10b.png"}   className="under"  /></>:<></>}
       {data[1].led10=="g"?<><img src={"/pics/right/3_button_keyboard_10_to_12/10g.png"}   className="under"  /></>:<></>}
     
     
     
         {/* Led 11 */}
       {data[1].led11=="r"?<><img src={"/pics/right/3_button_keyboard_10_to_12/11r.png"}   className="under"  /></>:<></>}
       {data[1].led11=="y"?<><img src={"/pics/right/3_button_keyboard_10_to_12/11y.png"}   className="under"  /></>:<></>}
       {data[1].led11=="b"?<><img src={"/pics/right/3_button_keyboard_10_to_12/11b.png"}   className="under"  /></>:<></>}
       {data[1].led11=="g"?<><img src={"/pics/right/3_button_keyboard_10_to_12/11g.png"}   className="under"  /></>:<></>}
     
     
     
         {/* Led 12 */}
         {data[1].led12=="r"?<><img src={"/pics/right/3_button_keyboard_10_to_12/12r.png"}   className="under"  /></>:<></>}
       {data[1].led12=="y"?<><img src={"/pics/right/3_button_keyboard_10_to_12/12y.png"}   className="under"  /></>:<></>}
       {data[1].led12=="b"?<><img src={"/pics/right/3_button_keyboard_10_to_12/12b.png"}   className="under"  /></>:<></>}
       {data[1].led12=="g"?<><img src={"/pics/right/3_button_keyboard_10_to_12/12g.png"}   className="under"  /></>:<></>}
     
     
         {/* Led 13 */}
         {data[1].led13=="r"?<><img src={"/pics/right/3_button_keyboard_13_15_pos_B/13r.png"}   className="under"  /></>:<></>}
       {data[1].led13=="y"?<><img src={"/pics/right/3_button_keyboard_13_15_pos_B/13y.png"}   className="under"  /></>:<></>}
       {data[1].led13=="b"?<><img src={"/pics/right/3_button_keyboard_13_15_pos_B/13b.png"}   className="under"  /></>:<></>}
       {data[1].led13=="g"?<><img src={"/pics/right/3_button_keyboard_13_15_pos_B/13g.png"}   className="under"  /></>:<></>}
     
     
     
         {/* Led 14 */}
       {data[1].led14=="r"?<><img src={"/pics/right/3_button_keyboard_13_15_pos_B/14r.png"}   className="under"  /></>:<></>}
       {data[1].led14=="y"?<><img src={"/pics/right/3_button_keyboard_13_15_pos_B/14y.png"}   className="under"  /></>:<></>}
       {data[1].led14=="b"?<><img src={"/pics/right/3_button_keyboard_13_15_pos_B/14b.png"}   className="under"  /></>:<></>}
       {data[1].led14=="g"?<><img src={"/pics/right/3_button_keyboard_13_15_pos_B/14g.png"}   className="under"  /></>:<></>}
     
     
     
         {/* Led 15 */}
       {data[1].led15=="r"?<><img src={"/pics/right/3_button_keyboard_13_15_pos_B/15r.png"}   className="under"  /></>:<></>}
       {data[1].led15=="y"?<><img src={"/pics/right/3_button_keyboard_13_15_pos_B/15y.png"}   className="under"  /></>:<></>}
       {data[1].led15=="b"?<><img src={"/pics/right/3_button_keyboard_13_15_pos_B/15b.png"}   className="under"  /></>:<></>}
       {data[1].led15=="g"?<><img src={"/pics/right/3_button_keyboard_13_15_pos_B/15g.png"}   className="under"  /></>:<></>}
        
      
         {/* Led 16 pos a */}
       {data[1].led16=="r" && data[1].button_16_to_18_position=="a" ?<><img src={"/pics/right/3_button_keyboard_16_18/pos_a/1r.png"}   className="under"  /></>:<></>}
       {data[1].led16=="y" && data[1].button_16_to_18_position=="a"?<><img src={"/pics/right/3_button_keyboard_16_18/pos_a/1y.png"}   className="under"  /></>:<></>}
       {data[1].led16=="b" && data[1].button_16_to_18_position=="a"?<><img src={"/pics/right/3_button_keyboard_16_18/pos_a/1b.png"}   className="under"  /></>:<></>}
       {data[1].led16=="g" && data[1].button_16_to_18_position=="a"?<><img src={"/pics/right/3_button_keyboard_16_18/pos_a/1g.png"}   className="under"  /></>:<></>}
         
         {/* Led 17 pos a */}
       {data[1].led17=="r" && data[1].button_16_to_18_position=="a" ?<><img src={"/pics/right/3_button_keyboard_16_18/pos_a/2r.png"}   className="under"  /></>:<></>}
       {data[1].led17=="y" && data[1].button_16_to_18_position=="a"?<><img src={"/pics/right/3_button_keyboard_16_18/pos_a/2y.png"}   className="under"  /></>:<></>}
       {data[1].led17=="b" && data[1].button_16_to_18_position=="a"?<><img src={"/pics/right/3_button_keyboard_16_18/pos_a/2b.png"}   className="under"  /></>:<></>}
       {data[1].led17=="g" && data[1].button_16_to_18_position=="a"?<><img src={"/pics/right/3_button_keyboard_16_18/pos_a/2g.png"}   className="under"  /></>:<></>}
         
         {/* Led 18 pos a */}
       {data[1].led18=="r" && data[1].button_16_to_18_position=="a" ?<><img src={"/pics/right/3_button_keyboard_16_18/pos_a/3r.png"}   className="under"  /></>:<></>}
       {data[1].led18=="y" && data[1].button_16_to_18_position=="a"?<><img src={"/pics/right/3_button_keyboard_16_18/pos_a/3y.png"}   className="under"  /></>:<></>}
       {data[1].led18=="b" && data[1].button_16_to_18_position=="a"?<><img src={"/pics/right/3_button_keyboard_16_18/pos_a/3b.png"}   className="under"  /></>:<></>}
       {data[1].led18=="g" && data[1].button_16_to_18_position=="a"?<><img src={"/pics/right/3_button_keyboard_16_18/pos_a/3g.png"}   className="under"  /></>:<></>}
         
         {/* Led 16 pos c */}
       {data[1].led16=="r" && data[1].button_16_to_18_position=="c" ?<><img src={"/pics/right/3_button_keyboard_16_18/pos_c/1r.png"}   className="under"  /></>:<></>}
       {data[1].led16=="y" && data[1].button_16_to_18_position=="c"?<><img src={"/pics/right/3_button_keyboard_16_18/pos_c/1y.png"}   className="under"  /></>:<></>}
       {data[1].led16=="b" && data[1].button_16_to_18_position=="c"?<><img src={"/pics/right/3_button_keyboard_16_18/pos_c/1b.png"}   className="under"  /></>:<></>}
       {data[1].led16=="g" && data[1].button_16_to_18_position=="c"?<><img src={"/pics/right/3_button_keyboard_16_18/pos_c/1g.png"}   className="under"  /></>:<></>}
         
         {/* Led 17 pos c */}
       {data[1].led17=="r" && data[1].button_16_to_18_position=="c" ?<><img src={"/pics/right/3_button_keyboard_16_18/pos_c/2r.png"}   className="under"  /></>:<></>}
       {data[1].led17=="y" && data[1].button_16_to_18_position=="c"?<><img src={"/pics/right/3_button_keyboard_16_18/pos_c/2y.png"}   className="under"  /></>:<></>}
       {data[1].led17=="b" && data[1].button_16_to_18_position=="c"?<><img src={"/pics/right/3_button_keyboard_16_18/pos_c/2b.png"}   className="under"  /></>:<></>}
       {data[1].led17=="g" && data[1].button_16_to_18_position=="c"?<><img src={"/pics/right/3_button_keyboard_16_18/pos_c/2g.png"}   className="under"  /></>:<></>}
         
         {/* Led 18 pos c */}
       {data[1].led18=="r" && data[1].button_16_to_18_position=="c" ?<><img src={"/pics/right/3_button_keyboard_16_18/pos_c/3r.png"}   className="under"  /></>:<></>}
       {data[1].led18=="y" && data[1].button_16_to_18_position=="c"?<><img src={"/pics/right/3_button_keyboard_16_18/pos_c/3y.png"}   className="under"  /></>:<></>}
       {data[1].led18=="b" && data[1].button_16_to_18_position=="c"?<><img src={"/pics/right/3_button_keyboard_16_18/pos_c/3b.png"}   className="under"  /></>:<></>}
       {data[1].led18=="g" && data[1].button_16_to_18_position=="c"?<><img src={"/pics/right/3_button_keyboard_16_18/pos_c/3g.png"}   className="under"  /></>:<></>}
         
         {/* Led 16 pos d */}
       {data[1].led16=="r" && data[1].button_16_to_18_position=="d" ?<><img src={"/pics/right/3_button_keyboard_16_18/pos_d/1r.png"}   className="under"  /></>:<></>}
       {data[1].led16=="y" && data[1].button_16_to_18_position=="d"?<><img src={"/pics/right/3_button_keyboard_16_18/pos_d/1y.png"}   className="under"  /></>:<></>}
       {data[1].led16=="b" && data[1].button_16_to_18_position=="d"?<><img src={"/pics/right/3_button_keyboard_16_18/pos_d/1b.png"}   className="under"  /></>:<></>}
       {data[1].led16=="g" && data[1].button_16_to_18_position=="d"?<><img src={"/pics/right/3_button_keyboard_16_18/pos_d/1g.png"}   className="under"  /></>:<></>}
         
         {/* Led 17 pos d */}
       {data[1].led17=="r" && data[1].button_16_to_18_position=="d" ?<><img src={"/pics/right/3_button_keyboard_16_18/pos_d/2r.png"}   className="under"  /></>:<></>}
       {data[1].led17=="y" && data[1].button_16_to_18_position=="d"?<><img src={"/pics/right/3_button_keyboard_16_18/pos_d/2y.png"}   className="under"  /></>:<></>}
       {data[1].led17=="b" && data[1].button_16_to_18_position=="d"?<><img src={"/pics/right/3_button_keyboard_16_18/pos_d/2b.png"}   className="under"  /></>:<></>}
       {data[1].led17=="g" && data[1].button_16_to_18_position=="d"?<><img src={"/pics/right/3_button_keyboard_16_18/pos_d/2g.png"}   className="under"  /></>:<></>}
         
         {/* Led 18 pos d */}
       {data[1].led18=="r" && data[1].button_16_to_18_position=="d" ?<><img src={"/pics/right/3_button_keyboard_16_18/pos_d/3r.png"}   className="under"  /></>:<></>}
       {data[1].led18=="y" && data[1].button_16_to_18_position=="d"?<><img src={"/pics/right/3_button_keyboard_16_18/pos_d/3y.png"}   className="under"  /></>:<></>}
       {data[1].led18=="b" && data[1].button_16_to_18_position=="d"?<><img src={"/pics/right/3_button_keyboard_16_18/pos_d/3b.png"}   className="under"  /></>:<></>}
       {data[1].led18=="g" && data[1].button_16_to_18_position=="d"?<><img src={"/pics/right/3_button_keyboard_16_18/pos_d/3g.png"}   className="under"  /></>:<></>}
         
             {/* Led 19 */}
       {data[1].led19=="r" && data[1].button_19_to_21_q ?<><img src={"/pics/right/3_button_keyboard_19_21/1r.png"}   className="under"  /></>:<></>}
       {data[1].led19=="y" && data[1].button_19_to_21_q?<><img src={"/pics/right/3_button_keyboard_19_21/1y.png"}   className="under"  /></>:<></>}
       {data[1].led19=="b" && data[1].button_19_to_21_q?<><img src={"/pics/right/3_button_keyboard_19_21/1b.png"}   className="under"  /></>:<></>}
       {data[1].led19=="g" && data[1].button_19_to_21_q?<><img src={"/pics/right/3_button_keyboard_19_21/1g.png"}   className="under"  /></>:<></>}
             {/* Led 20 */}
       {data[1].led20=="r" && data[1].button_19_to_21_q ?<><img src={"/pics/right/3_button_keyboard_19_21/2r.png"}   className="under"  /></>:<></>}
       {data[1].led20=="y" && data[1].button_19_to_21_q?<><img src={"/pics/right/3_button_keyboard_19_21/2y.png"}   className="under"  /></>:<></>}
       {data[1].led20=="b" && data[1].button_19_to_21_q?<><img src={"/pics/right/3_button_keyboard_19_21/2b.png"}   className="under"  /></>:<></>}
       {data[1].led20=="g" && data[1].button_19_to_21_q?<><img src={"/pics/right/3_button_keyboard_19_21/2g.png"}   className="under"  /></>:<></>}
             {/* Led 21 */}
       {data[1].led21=="r" && data[1].button_19_to_21_q ?<><img src={"/pics/right/3_button_keyboard_19_21/3r.png"}   className="under"  /></>:<></>}
       {data[1].led21=="y" && data[1].button_19_to_21_q?<><img src={"/pics/right/3_button_keyboard_19_21/3y.png"}   className="under"  /></>:<></>}
       {data[1].led21=="b" && data[1].button_19_to_21_q?<><img src={"/pics/right/3_button_keyboard_19_21/3b.png"}   className="under"  /></>:<></>}
       {data[1].led21=="g" && data[1].button_19_to_21_q?<><img src={"/pics/right/3_button_keyboard_19_21/3g.png"}   className="under"  /></>:<></>}
         
     
     
     
     
     
       {data[1].button_10_to_12_q?<><img src={"/pics/right/3_button_keyboard_10_to_12/k10_12b.png"}   className="under"  /></>:<></>}
       {data[1].button_13_to_15_q?<><img src={"/pics/right/3_button_keyboard_13_15_pos_B/k13_15b.png"}   className="under"  /></>:<></>}
     
     
       {data[1].button_16_to_18_position=="a"?<>
       <img src={"/pics/right/3_button_keyboard_16_18/pos_a/k16_18a.png"}   className="under"  />
       
       
       
       </>:<></>}
       {data[1].button_16_to_18_position=="c"?<><img src={"/pics/right/3_button_keyboard_16_18/pos_c/k16_18c.png"}   className="under"  /></>:<></>}
       {data[1].button_16_to_18_position=="d"?<><img src={"/pics/right/3_button_keyboard_16_18/pos_d/k16_18d.png"}   className="under"  /></>:<></>}
     
     {
       data[1].button_19_to_21_q?<><img src={"/pics/right/3_button_keyboard_19_21/k19_21a.png"}   className="under"  /></>:<></>
     }
  
     
     
  {
    data[1].roller_htw!=""?<><img src={"/pics/right/Roller_HTW/pic.PNG"}   className="under"  /></>:<></>
  }

  {
    data[1].extra_switch_op_q=="A"?<><img src={"/pics/right/Rocker_switch_2_way/RS2A.PNG"}   className="under"  /></>:<></>
  }
  {
    data[1].extra_switch_op_q=="B"?<><img src={"/pics/right/Rocker_switch_2_way/RS2B.PNG"}   className="under"  /></>:<></>
  }
  {
    data[1].extra_switch_op_q=="C"?<><img src={"/pics/right/Rocker_switch_2_way/RS2C.PNG"}   className="under"  /></>:<></>
  }
  {
    data[1].push_button_switch=="yes"?<><img src={"/pics/right/Push_button_switch/pba1.png"}   className="under"  /></>:<></>
  }
  {/*  LED Indicator */}
  {
    data[1].led_indicator_option=="yes"?<><img src={"/pics/right/led_Indicator/1.png"}   className="under"  /></>:<></>
  }
  
  {/* Logo */}
  {
    data[1].customer_logo=="true"?<><img src={"/pics/right/Customer_designed_decal/cd2.png"}   className="under"  /></>:<><img src={"/pics/right/Customer_designed_decal/cd1.png"}   className="under"  /> </>
  }

     
       </>
       
       
        </td>
    
    
        
        </tr>
       
    </table>
    
    </Grid>
   
   
  

</Grid>
<br/>
<br/>
<br/>
<div sx={{marginBottom:"0"}}>
<Divider  sx={{border: "5px solid #878800",}} />
<Divider  sx={{border: "5px solid black",marginTop:"7px",marginBottom:"7px"}} />
<Divider  sx={{border: "5px solid #878800"}} />
</div>

</div>
</>

  );
}