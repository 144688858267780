import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import { json } from 'react-router-dom';





export default function BasicTable() {
    const [array, setarray] = React.useState([]);
    const [data, setData] = React.useState([]);
    
        // setarray(JSON.parse(localStorage.getItem("array")))
        // console.log(JSON.parse(localStorage.getItem("array")))
        
  const add = async () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const id = urlParams.get('id')

    await fetch(`/api/get_by_id.php?id=${id}`)
      .then((response) => response.json())
      .then((json) => {
        
        setarray(JSON.parse(json[0].array))
        setData(json)
        console.log("ubl account active",json)


      })
      .catch((err) => {
        console.log(err);
      });
  };
      

      React.useEffect(() => {
        add()
      }, []);


  return (
    <Container sx={{ minWidth: 650,marginTop:"50px" }}>


        {
            array.length<1?<><h1>Loading......</h1></>:
            <>
            <h1>ExciPad Configurator</h1>
              <TableContainer  component={Paper}>
      <Table  aria-label="simple table">
      <TableRow>
        <TableCell  sx={{fontWeight:"bold",fontSize:"20px",color:"black",background:"#ebebeb"}}>Email</TableCell>
        <TableCell sx={{fontWeight:"bold",fontSize:"20px",color:"black"}}>{data.length>0?<>{data[0].email}</>:""}</TableCell>
        <TableCell  sx={{fontWeight:"bold",fontSize:"20px",color:"black",background:"#ebebeb"}}>Company</TableCell>
        <TableCell  sx={{fontWeight:"bold",fontSize:"20px",color:"black"}}>{data.length>0?<>{data[0].company}</>:""}</TableCell>
        </TableRow>
      
      <TableRow>
        <TableCell  sx={{fontWeight:"bold",fontSize:"20px",color:"black",background:"#ebebeb"}}>Brand</TableCell>
        <TableCell  sx={{fontWeight:"bold",fontSize:"20px",color:"black"}}>{data.length>0?<>{data[0].name}</>:""}</TableCell>
        <TableCell  sx={{fontWeight:"bold",fontSize:"20px",color:"black",background:"#ebebeb"}}>Type</TableCell>
        <TableCell  sx={{fontWeight:"bold",fontSize:"20px",color:"black"}}>{data.length>0?<>{data[0].m}</>:""}</TableCell>
        </TableRow>
      <TableRow>
        <TableCell sx={{fontWeight:"bold",fontSize:"20px",color:"black",background:"#ebebeb"}}>Model</TableCell>
        <TableCell  sx={{fontWeight:"bold",fontSize:"20px",color:"black"}}>{data.length>0?<>{data[0].model}</>:""}</TableCell>
        <TableCell  sx={{fontWeight:"bold",fontSize:"20px",color:"black",background:"#ebebeb"}}>Phone</TableCell>
        <TableCell  sx={{fontWeight:"bold",fontSize:"20px",color:"black"}}>{data.length>0?<>{data[0].phone}</>:""}</TableCell>
        </TableRow>
      <TableRow>
        <TableCell  sx={{fontWeight:"bold",fontSize:"20px",color:"black",background:"#ebebeb"}}>Datum</TableCell>
        <TableCell  sx={{fontWeight:"bold",fontSize:"20px",color:"black"}}>{data.length>0?<>{data[0].da}</>:""}</TableCell>
        <TableCell  sx={{fontWeight:"bold",fontSize:"20px",color:"black",background:"#ebebeb"}}></TableCell>
        <TableCell></TableCell>
        </TableRow>
      </Table>
      </TableContainer>
      <br/>
      <br/>
      <h2>Order Details</h2>

             <TableContainer  component={Paper}>
      <Table  aria-label="simple table">
        <TableHead>
          <TableRow>
            
            <TableCell sx={{fontWeight:"bold",fontSize:"25px",background:"#ebebeb"}}>Detail</TableCell>
            <TableCell sx={{fontWeight:"bold",fontSize:"25px",background:"#ebebeb"}} >Left</TableCell>
            <TableCell sx={{fontWeight:"bold",fontSize:"25px",background:"#ebebeb"}} >Right</TableCell>
           
          </TableRow>
        </TableHead>
        <TableBody>
          
            <TableRow>
              <TableCell>EP2006 Armrest bracket Sittab</TableCell>
              <TableCell>{array[0].armrest}</TableCell>
              <TableCell>{array[1].armrest}</TableCell>
            </TableRow>

            {/* <TableRow>
              <TableCell>EP2006 Joystick Otto JHM</TableCell>
              <TableCell>{array[0].jonstick_otto}</TableCell>
              <TableCell>{array[1].jonstick_otto}</TableCell>
            </TableRow> */}

            <TableRow>
              <TableCell>EP2006 Joystick Otto JHM *</TableCell>
              <TableCell>{array[0].jonstick_otto=="std"?"J1":array[0].jonstick_otto=="extended"?"J2":array[0].jonstick_otto=="5-button"?"J3":""}</TableCell>
              <TableCell>{array[1].jonstick_otto=="std"?"J1":array[1].jonstick_otto=="extended"?"J2":array[0].jonstick_otto=="5-button"?"J3":""}</TableCell>
            </TableRow>

            <TableRow>
              <TableCell>Extra Joystick options</TableCell>
              
              <TableCell>{array[0].jonstick_htl_tnpe==""?"":<>Jx{array[0].jonstick_htl_tnpe}</>}</TableCell>
              <TableCell>{array[1].jonstick_htl_tnpe==""?"":<>Jx{array[1].jonstick_htl_tnpe}</>}</TableCell>
            </TableRow>

            <TableRow>
              <TableCell>keyboard 1 to 9</TableCell>
              <TableCell>{array[0].button_1_to_9_q==true?"Yes":""}</TableCell>
              <TableCell>{array[1].button_1_to_9_q==true?"Yes":""}</TableCell>
            </TableRow>
           
              <TableRow>
              <TableCell>LED 1 </TableCell>
              <TableCell>{array[0].led1=="g"?"Green":array[0].led1=="y"?"Yellow":array[0].led1=="r"?"Red":array[0].led1=="b"?"Blue":""}</TableCell>
              <TableCell>{array[1].led1=="g"?"Green":array[1].led1=="y"?"Yellow":array[1].led1=="r"?"Red":array[1].led1=="b"?"Blue":""}</TableCell>
              </TableRow>
              <TableRow>
              <TableCell>LED 3 </TableCell>
              <TableCell>{array[0].led3=="g"?"Green":array[0].led3=="y"?"Yellow":array[0].led3=="r"?"Red":array[0].led3=="b"?"Blue":""}</TableCell>
              <TableCell>{array[1].led3=="g"?"Green":array[1].led3=="y"?"Yellow":array[1].led3=="r"?"Red":array[1].led3=="b"?"Blue":""}</TableCell>
              </TableRow>
              <TableRow>
              <TableCell>LED 4 </TableCell>
              <TableCell>{array[0].led4=="g"?"Green":array[0].led4=="y"?"Yellow":array[0].led4=="r"?"Red":array[0].led4=="b"?"Blue":""}</TableCell>
              <TableCell>{array[1].led4=="g"?"Green":array[1].led4=="y"?"Yellow":array[1].led4=="r"?"Red":array[1].led4=="b"?"Blue":""}</TableCell>
              </TableRow>
              <TableRow>
              <TableCell>LED 6 </TableCell>
              <TableCell>{array[0].led6=="g"?"Green":array[0].led6=="y"?"Yellow":array[0].led6=="r"?"Red":array[0].led6=="b"?"Blue":""}</TableCell>
              <TableCell>{array[1].led6=="g"?"Green":array[1].led6=="y"?"Yellow":array[1].led6=="r"?"Red":array[1].led6=="b"?"Blue":""}</TableCell>
              </TableRow>
              <TableRow>
              <TableCell>LED 7 </TableCell>
              <TableCell>{array[0].led7=="g"?"Green":array[0].led7=="y"?"Yellow":array[0].led7=="r"?"Red":array[0].led7=="b"?"Blue":""}</TableCell>
              <TableCell>{array[1].led7=="g"?"Green":array[1].led7=="y"?"Yellow":array[1].led7=="r"?"Red":array[1].led7=="b"?"Blue":""}</TableCell>
              </TableRow>
              <TableRow>
              <TableCell>LED 9 </TableCell>
              <TableCell>{array[0].led9=="g"?"Green":array[0].led9=="y"?"Yellow":array[0].led9=="r"?"Red":array[0].led9=="b"?"Blue":""}</TableCell>
              <TableCell>{array[1].led9=="g"?"Green":array[1].led9=="y"?"Yellow":array[1].led9=="r"?"Red":array[1].led9=="b"?"Blue":""}</TableCell>
              </TableRow>

              <TableRow>
              <TableCell>keyboard 10 to 12</TableCell>
              <TableCell>{array[0].button_10_to_12_q==true?"Yes":""}</TableCell>
              <TableCell>{array[1].button_10_to_12_q==true?"Yes":""}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>LED 10 </TableCell>
              <TableCell>{array[0].led10=="g"?"Green":array[0].led10=="y"?"Yellow":array[0].led10=="r"?"Red":array[0].led10=="b"?"Blue":""}</TableCell>
              <TableCell>{array[1].led10=="g"?"Green":array[1].led10=="y"?"Yellow":array[1].led10=="r"?"Red":array[1].led10=="b"?"Blue":""}</TableCell>
              </TableRow>
              <TableRow>
              <TableCell>LED 11 </TableCell>
              <TableCell>{array[0].led11=="g"?"Green":array[0].led11=="y"?"Yellow":array[0].led11=="r"?"Red":array[0].led11=="b"?"Blue":""}</TableCell>
              <TableCell>{array[1].led11=="g"?"Green":array[1].led11=="y"?"Yellow":array[1].led11=="r"?"Red":array[1].led11=="b"?"Blue":""}</TableCell>
              </TableRow>
              <TableRow>
              <TableCell>LED 12 </TableCell>
              <TableCell>{array[0].led12=="g"?"Green":array[0].led12=="y"?"Yellow":array[0].led12=="r"?"Red":array[0].led12=="b"?"Blue":""}</TableCell>
              <TableCell>{array[1].led12=="g"?"Green":array[1].led12=="y"?"Yellow":array[1].led12=="r"?"Red":array[1].led12=="b"?"Blue":""}</TableCell>
              </TableRow>
              <TableRow>
              <TableCell>keyboard 13 to 15</TableCell>
              <TableCell>{array[0].button_13_to_15_q==true?"Yes":""}</TableCell>
              <TableCell>{array[1].button_13_to_15_q==true?"Yes":""}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>LED 13 </TableCell>
              <TableCell>{array[0].led13=="g"?"Green":array[0].led13=="y"?"Yellow":array[0].led13=="r"?"Red":array[0].led13=="b"?"Blue":""}</TableCell>
              <TableCell>{array[1].led13=="g"?"Green":array[1].led13=="y"?"Yellow":array[1].led13=="r"?"Red":array[1].led13=="b"?"Blue":""}</TableCell>
              </TableRow>
              <TableRow>
              <TableCell>LED 14 </TableCell>
              <TableCell>{array[0].led14=="g"?"Green":array[0].led14=="y"?"Yellow":array[0].led14=="r"?"Red":array[0].led14=="b"?"Blue":""}</TableCell>
              <TableCell>{array[1].led14=="g"?"Green":array[1].led14=="y"?"Yellow":array[1].led14=="r"?"Red":array[1].led14=="b"?"Blue":""}</TableCell>
              </TableRow>
              <TableRow>
              <TableCell>LED 15 </TableCell>
              <TableCell>{array[0].led15=="g"?"Green":array[0].led15=="y"?"Yellow":array[0].led15=="r"?"Red":array[0].led15=="b"?"Blue":""}</TableCell>
              <TableCell>{array[1].led15=="g"?"Green":array[1].led15=="y"?"Yellow":array[1].led15=="r"?"Red":array[1].led15=="b"?"Blue":""}</TableCell>
              </TableRow>
              <TableRow>
              <TableCell>keyboard 16 to 18</TableCell>
              <TableCell> {array[0].button_16_to_18_position==""?"":<>Pos {array[0].button_16_to_18_position}</>}</TableCell>
              <TableCell> {array[1].button_16_to_18_position==""?"":<>Pos {array[1].button_16_to_18_position}</>}</TableCell>
             
            </TableRow>
            <TableRow>
              <TableCell>LED 16 </TableCell>
              <TableCell>{array[0].led16=="g"?"Green":array[0].led16=="y"?"Yellow":array[0].led16=="r"?"Red":array[0].led16=="b"?"Blue":""}</TableCell>
              <TableCell>{array[1].led16=="g"?"Green":array[1].led16=="y"?"Yellow":array[1].led16=="r"?"Red":array[1].led16=="b"?"Blue":""}</TableCell>
              </TableRow>
              <TableRow>
              <TableCell>LED 17 </TableCell>
              <TableCell>{array[0].led17=="g"?"Green":array[0].led17=="y"?"Yellow":array[0].led17=="r"?"Red":array[0].led17=="b"?"Blue":""}</TableCell>
              <TableCell>{array[1].led17=="g"?"Green":array[1].led17=="y"?"Yellow":array[1].led17=="r"?"Red":array[1].led17=="b"?"Blue":""}</TableCell>
              </TableRow>
              <TableRow>
              <TableCell>LED 18 </TableCell>
              <TableCell>{array[0].led18=="g"?"Green":array[0].led18=="y"?"Yellow":array[0].led18=="r"?"Red":array[0].led18=="b"?"Blue":""}</TableCell>
              <TableCell>{array[1].led18=="g"?"Green":array[1].led18=="y"?"Yellow":array[1].led18=="r"?"Red":array[1].led18=="b"?"Blue":""}</TableCell>
              </TableRow>


              <TableRow>
              <TableCell>keyboard 19 to 21</TableCell>
              <TableCell> {array[0].button_1_to_9_q?"Yes":<></>}</TableCell>
              <TableCell> {array[1].button_1_to_9_q?"Yes":<></>}</TableCell>
             
            </TableRow>
            <TableRow>
              <TableCell>LED 19 </TableCell>
              <TableCell>{array[0].led19=="g"?"Green":array[0].led19=="y"?"Yellow":array[0].led19=="r"?"Red":array[0].led19=="b"?"Blue":""}</TableCell>
              <TableCell>{array[1].led19=="g"?"Green":array[1].led19=="y"?"Yellow":array[1].led19=="r"?"Red":array[1].led19=="b"?"Blue":""}</TableCell>
              </TableRow>
              <TableRow>
              <TableCell>LED 20 </TableCell>
              <TableCell>{array[0].led20=="g"?"Green":array[0].led20=="y"?"Yellow":array[0].led20=="r"?"Red":array[0].led20=="b"?"Blue":""}</TableCell>
              <TableCell>{array[1].led20=="g"?"Green":array[1].led20=="y"?"Yellow":array[1].led20=="r"?"Red":array[1].led20=="b"?"Blue":""}</TableCell>
              </TableRow>
              <TableRow>
              <TableCell>LED 21 </TableCell>
              <TableCell>{array[0].led21=="g"?"Green":array[0].led21=="y"?"Yellow":array[0].led21=="r"?"Red":array[0].led21=="b"?"Blue":""}</TableCell>
              <TableCell>{array[1].led21=="g"?"Green":array[1].led21=="y"?"Yellow":array[1].led21=="r"?"Red":array[1].led21=="b"?"Blue":""}</TableCell>
              </TableRow>
              <TableRow>
              <TableCell>Roller Wheel options </TableCell>
              <TableCell>{array[0].roller_htw=="Roller_HTW_Friction"?"R1B":array[0].roller_htw=="Roller_HTW_Spring_Return"?"R2B":""}</TableCell>
              <TableCell>{array[1].roller_htw=="Roller_HTW_Friction"?"R1B":array[1].roller_htw=="Roller_HTW_Spring_Return"?"R2B":""}</TableCell>
             
              </TableRow>
              <TableRow>
              <TableCell>EP2006 LED Circuit board</TableCell>
              <TableCell>{array[0].led_indicator_option=="yes"?"Yes":""}</TableCell>
              <TableCell>{array[1].led_indicator_option=="yes"?"Yes":""}</TableCell>
             
              </TableRow>
              <TableRow>
              <TableCell>Extra Switch Option</TableCell>
              <TableCell>{array[0].extra_switch_op_selected=="A1"?"RS2A":array[0].extra_switch_op_selected=="A2"?"RS3A":array[0].extra_switch_op_selected=="B1"?"RS2B":array[0].extra_switch_op_selected=="B2"?"RS3B":array[0].extra_switch_op_selected=="C1"?"RS2C":array[0].extra_switch_op_selected=="C2"?"RS3C":""}</TableCell>
              <TableCell>{array[1].extra_switch_op_selected=="A1"?"RS2A":array[1].extra_switch_op_selected=="A2"?"RS3A":array[1].extra_switch_op_selected=="B1"?"RS2B":array[1].extra_switch_op_selected=="B2"?"RS3B":array[1].extra_switch_op_selected=="C1"?"RS2C":array[1].extra_switch_op_selected=="C2"?"RS3C":""}</TableCell>
              </TableRow>

              <TableRow>
              <TableCell>Push Button Switchs</TableCell>
              <TableCell>{array[0].push_button_switch=="yes"?"Yes":array[0].push_button_switch=="no"?"":""}</TableCell>
              <TableCell>{array[1].push_button_switch=="yes"?"Yes":array[1].push_button_switch=="no"?"":""}</TableCell>
             
              </TableRow>
              <TableRow>
              <TableCell>Customer designed options</TableCell>
              <TableCell>{array[0].customer_logo?"PB1A":""}</TableCell>
              <TableCell>{array[1].customer_logo?"PB1A":""}</TableCell>
             
              </TableRow>
            
        
        </TableBody>
      </Table>
    </TableContainer>
            </>
        }

        </Container>

   
  );
}